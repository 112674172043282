import { addYears } from 'date-fns';

export interface MonthOptionSelect {
  label: string
  value: number
}

export const monthValues = (): MonthOptionSelect[] => {
  const months: string[] = [
    'January', 'February', 'March',
    'April', 'May', 'June',
    'July', 'August', 'September',
    'October', 'November', 'December'
  ];
  return months.map((m: string, i: number) => ({ label: m, value: (i + 1) }));
};

export const yearValues = (numOfYears = 15): number[] => {
  const currentDate: Date = new Date();
  const targetDate: Date = addYears(currentDate, numOfYears);
  const currentYear: number = currentDate.getFullYear();
  const targetYear: number = targetDate.getFullYear();

  return [...Array(targetYear - currentYear)].map((_: any, i: number) => (currentYear + i));
};
