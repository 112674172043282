import {
  type AddressResponse,
  type ContactResponse,
  type EmailAddressResponse,
  type PhoneNumberResponse
} from '../types';
import { observable, computed } from 'mobx';

export default class Contact implements ContactResponse {
  id: string;
  deleted: boolean;
  testMode: boolean;
  contactCode: string;
  ownerId: string;
  preferredCurrency: string;
  addresses: AddressResponse[];
  phoneNumbers: PhoneNumberResponse[];
  @observable emailAddresses: EmailAddressResponse[];
  @observable firstName = '';
  @observable lastName = '';
  @observable errors: object | null = {};

  static fromJSON(json: ContactResponse): Contact {
    const contact = new Contact();
    contact.id = json.id;
    contact.deleted = json.deleted;
    contact.testMode = json.testMode;
    contact.contactCode = json.contactCode;
    contact.ownerId = json.ownerId;
    contact.preferredCurrency = json.preferredCurrency;
    contact.addresses = json.addresses;
    contact.emailAddresses = json.emailAddresses;
    contact.phoneNumbers = json.phoneNumbers;
    contact.firstName = json.firstName || '';
    contact.lastName = json.lastName || '';
    return contact;
  }

  @computed get preferredEmail(): string | undefined {
    const preferred = this.emailAddresses.find(email => email.preferred);
    if (preferred) {
      return preferred.address;
    } else {
      return '';
    }
  }
}
