import * as Cookies from 'js-cookie';
import { camelizeKeys } from 'humps';
import { type PortalResponse } from '../types';

const PREVIEW_DATA_COOKIE_KEY = 'previewData';

export const previewDataFromCookie = (): PortalResponse => {
  // Can't use Cookies.getJSON because it won't decode spaces correctly
  const rawPreviewData = Cookies.get(PREVIEW_DATA_COOKIE_KEY);
  if (!rawPreviewData) {
    throw new Error('Could not find preview data cookie');
  } else {
    const decodedPreviewData: string = decodeURIComponent(rawPreviewData);
    return camelizeKeys(JSON.parse(decodedPreviewData)) as PortalResponse;
  }
};
