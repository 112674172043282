import * as React from 'react';

const Danger = () => (
  <svg data-testid='danger-icon' width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
    <defs>
      <path
        d={`M18,30.8 C14.4654177,30.8 11.260557,29.3668861 8.94440506,27.0515443 L8.94440506,27.0507342
          L8.94683544,27.0483038 C6.63149367,24.7321519 5.2,21.5337722 5.2,18 C5.2,14.467038
          6.63149367,11.2678481 8.94683544,8.9516962 L8.94440506,8.95007595 L8.94440506,8.94926582
          C11.260557,6.63311392 14.4654177,5.2 18,5.2 C25.0691646,5.2 30.8,10.9308354
          30.8,18 C30.8,25.0691646 25.0691646,30.8 18,30.8 M18,2 C13.5815696,2 9.5763038,3.79118987
          6.68010127,6.68658228 L6.68010127,6.68739241 L6.68334177,6.68982278 C3.78956962,9.58521519
          2,13.584 2,18 C2,22.4168101 3.78956962,26.4147848 6.68334177,29.3101772
          L6.68010127,29.3134177 L6.68010127,29.3142278 C9.5763038,32.2096203 13.5815696,34
          18,34 C26.8368608,34 34,26.8368608 34,18 C34,9.16313924 26.8368608,2
          18,2 M24.0759494,14.4052253 L21.7970633,12.1263392 L18,15.9242127 L14.2021266,12.1263392
          L11.9240506,14.4052253 L15.7211139,18.2022886 L11.9240506,22.000162 L14.2021266,24.2790481
          L18,20.4811747 L21.7970633,24.2790481 L24.0759494,22.000162 L20.2788861,18.2022886
          L24.0759494,14.4052253 Z`}
        id="path-1"
      />
    </defs>
    <g id="Page-1" style={{ stroke: 'none', strokeWidth: 1, fill: 'none', fillRule: 'evenodd' }}>
      <g id="icn_error">
        <mask id="mask-2" style={{ fill: 'white' }}>
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" style={{ fill: '#181F26' }} xlinkHref="#path-1" />
        <g id="COLOR/system-ERROR-status" style={{ mask: 'url(#mask-2)', fill: '#D32F2F' }}>
          <polygon id="clr" points="0 36 36 36 36 0 0 0" />
        </g>
      </g>
    </g>
  </svg>
);

const Close = () => (
  <svg data-testid='close-icon' id="icn-small-close-x" viewBox="0 0 36 36" height="12px" width="12px">
    <polygon
      className="icon-path-1"
      points="29.3 0 18 11.3 6.8 0 0 6.8 11.2 18 0 29.3 6.8 36 18 24.8 29.3 36 36 29.3 24.7 18 36 6.8 29.3 0"
    />
  </svg>

);

const Info = () => (
  <svg data-testid='info-icon' width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
    <defs>
      <path
        d={`M18,30.8 C14.4654177,30.8 11.260557,29.3668861 8.94440506,27.0515443
        L8.94440506,27.0507342 L8.94683544,27.0483038 C6.63149367,24.7321519 5.2,21.5337722 5.2,18
        C5.2,14.467038 6.63149367,11.2678481 8.94683544,8.9516962 L8.94440506,8.95007595
        L8.94440506,8.94926582 C11.260557,6.63311392 14.4654177,5.2 18,5.2 C25.0691646,5.2
        30.8,10.9308354 30.8,18 C30.8,25.0691646 25.0691646,30.8 18,30.8 M18,2 C26.8368608,2
        34,9.16313924 34,18 C34,26.8368608 26.8368608,34 18,34 C13.5815696,34
        9.5763038,32.2096203 6.68010127,29.3142278 L6.68010127,29.3134177 L6.68334177,29.3101772
        C3.78956962,26.4147848 2,22.4168101 2,18 C2,13.584 3.78956962,9.58521519 6.68334177,6.68982278
        L6.68010127,6.68739241 L6.68010127,6.68658228 C9.5763038,3.79118987 13.5815696,2
        18,2 Z M19.6202532,10.3020152 C19.6202532,9.40763544 18.8943797,8.68176203 18,8.68176203
        C17.1056203,8.68176203 16.3797468,9.40763544 16.3797468,10.3020152 C16.3797468,11.1963949
        17.1056203,11.9222684 18,11.9222684 C18.8943797,11.9222684 19.6202532,11.1963949
        19.6202532,10.3020152 Z M17.4600506,27.2422481 C18.0992405,27.2422481
        18.6347342,27.0915646 19.184,26.8501468 C20.0767595,26.5382481 21.2409114,25.2614886
        21.2409114,24.4813367 L19.9155443,23.5351089 C19.9155443,23.5351089 19.8207595,23.5602228
        19.8158987,23.6064 C19.7275949,24.4562228 18.8486076,25.2914633 18.0004051,25.2914633
        L17.9566582,25.2914633 C17.8262278,25.2655392 17.7014684,25.1942481 17.5872405,25.1237671
        C17.5677975,25.1116152 17.5694177,24.9520203 17.5694177,24.9520203 L19.6206582,14.3547544
        L14.7598987,14.3547544 L13.9497722,16.7851342 L16.3801519,16.7851342 L14.4512405,24.8207797
        L14.4358481,24.8864 C14.4358481,25.5361215 14.7420759,26.1145519 15.2176203,26.4847797
        C15.3577722,26.5998177 15.5619241,26.7027038 15.7263797,26.7926278 C16.2189367,27.0607797
        16.809519,27.2487291 17.4600506,27.2422481 Z`}
        id="path-1"
      />
    </defs>
    <g
      id="Page-1"
      style={{ stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' }}
    >
      <g id="icn_information">
        <mask id="mask-2" style={{ fill: 'white' }}>
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" style={{ fill: '#181F26' }} xlinkHref="#path-1" />
        <g id="COLOR/system-INFO-status" mask="url(#mask-2)" fill="#0360BE">
          <polygon id="clr" points="0 36 36 36 36 0 0 0" />
        </g>
      </g>
    </g>
  </svg>
);

const Success = () => (
  <svg data-testid='success-icon' width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
    <defs>
      <path
        d={`M18,2 C26.8368608,2 34,9.16313924 34,18 C34,26.8368608 26.8368608,34 18,34 C13.5815696,34
         9.5763038,32.2096203 6.68010127,29.3142278 L6.68010127,29.3134177 L6.68334177,29.3101772
         C3.78956962,26.4147848 2,22.4168101 2,18 C2,13.584 3.78956962,9.58521519 6.68334177,6.68982278
         L6.68010127,6.68739241 L6.68010127,6.68658228 C9.5763038,3.79118987 13.5815696,2 18,2 Z M18,30.8
         C25.0691646,30.8 30.8,25.0691646 30.8,18 C30.8,10.9308354 25.0691646,5.2 18,5.2 C14.4654177,5.2
         11.260557,6.63311392 8.94440506,8.94926582 L8.94440506,8.95007595 L8.94683544,8.9516962 C6.63149367,11.2678481
         5.2,14.467038 5.2,18 C5.2,21.5337722 6.63149367,24.7321519 8.94683544,27.0483038 L8.94440506,27.0507342
         L8.94440506,27.0515443 C11.260557,29.3668861 14.4654177,30.8 18,30.8 Z M24.3782392,11.7679104
         L26.3443161,13.2770221 L15.7059953,24.0872697 L9.65568389,18.0640041 L11.4295076,16.4416569
         L15.771163,20.8168155 L24.3782392,11.7679104 Z`}
        id="path-1"
      />
    </defs>
    <g
      id="Page-1"
      style={{ stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' }}
    >
      <g id="icn_success">
        <mask id="mask-2" style={{ fill: 'white' }} >
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Combined-Shape" style={{ fill: '#000000' }} xlinkHref="#path-1" />
        <g id="COLOR/system-SUCCESS-status" mask="url(#mask-2)" fill="#307733">
          <polygon id="clr" points="0 36 36 36 36 0 0 0" />
        </g>
      </g>
    </g>
  </svg>
);

const Checkmark = () => (
  <svg data-testid='checkmark-icon' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="checkmark" fill="#FFF">
        <path
          d={`M14.3205128,3.5764126 L8.03720513,13.1404126 L5.65836023,10.4014126 C5.09087434,9.7494126 4.12474959,
          9.6604126 3.5003153,10.2034126 C2.875881,10.7474126 2.82992263,11.7174126 3.39640943,12.3704126 L6.90223335,
          16.4064126 L6.90323244,16.4074126 C6.97916366,16.5084126 7.07008129,16.6004126 7.17398716,
          16.6814126 C7.82639611,17.1924126 8.76854258,17.0764126 9.27808097,16.4244126 L16.6823731,
          5.4244126 C17.1919115,4.7704126 17.0760165,3.8284126 16.4236075,3.3184126 C15.7721977,2.8074126 14.8300512,
          2.9244126 14.3205128,3.5764126`}
          id="Fill-1"
        />
      </g>
    </g>
  </svg>
);

export {
  Danger,
  Close,
  Info,
  Success,
  Checkmark
};
