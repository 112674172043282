import * as React from 'react';
import { inject, observer } from 'mobx-react';
import BillingInformation from './BillingInformation';
import PaymentInformation from './PaymentInformation';

const PaymentForm = inject('appStore')(observer(({ appStore: { makePayment } }: any) => {
  return (
    <div className="payment-step">
      <form
        className="payment-form"
        onSubmit={makePayment}
      >
        <BillingInformation/>
        <PaymentInformation/>
      </form>
    </div>
  );
}));

export default PaymentForm;