import { type Locale } from '../types';

const UPFCheckoutQAUrl = 'https://libs.qa.affinipay.com/upf-checkout/0.0.82/main.835392e945160f005823.bundle.js';

export const loadScript = (script: string): void => {
  const scriptEl = document.createElement('script');
  scriptEl.setAttribute('src', script);
  document.getElementsByTagName('head')[0].appendChild(scriptEl);
};

export const initializeUPF = (env: NodeJS.ProcessEnv) => {
  const upfJsUrl = env.REACT_APP_UPF_JS_URL ?? UPFCheckoutQAUrl;
  loadScript(upfJsUrl);
};

export type PrepaymentRequest = {
  amount: number,
  paymentType: 'prepayment',
  contactId: string,
  currency: string,
  testMode: boolean
}

export const generatePrepaymentBody = (appStore: any): PrepaymentRequest => {
  return {
    amount: appStore.paymentAmount,
    paymentType: 'prepayment',
    contactId: appStore.contact.id,
    currency: appStore.selectedInvoices[0].currency,
    testMode: appStore.testMode,
  };
};

export const getUserLocale = () => {
  if (navigator.languages != undefined) {
    return navigator.languages[0] as Locale;
  }
  const defaultLocale = 'en-US' as Locale;
  return navigator?.language || defaultLocale;
};