import * as React from 'react';
import type Invoice from '../stores/invoice';
import { type InvoiceAttachedFile } from '../types';
import attachmentIcon from '../images/icn_paperclip.svg';

const InvoiceAttachmentItem = (contactHash: string, attachment: InvoiceAttachedFile) => {
  return (
    <a
      key={attachment.id}
      href={`/portal/${contactHash}/attachments/${attachment.id}`}
      className="invoice-attachment"
      rel="noopener nofollow"
    >
      <img src={attachmentIcon} alt="File Attached" />
      {attachment.fileName}
    </a>
  );
};

const InvoiceAttachment = (contactHash: string, invoice: Invoice) => {
  return invoice.invoiceAttachments.map((a) => {
    return InvoiceAttachmentItem(contactHash, a);
  });
};

export default InvoiceAttachment;
