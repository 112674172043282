const privacyLinks: Record<string, string> = {
  'affinipay': 'https://affinipay.com/terms/privacy/',
  'lawpay': 'https://lawpay.com/terms/privacy/',
  'cpacharge': 'https://cpacharge.com/terms/privacy/',
  'clientpay': 'https://clientpay.com/terms/privacy/',
  'medpay': 'https://www.medpay.com/terms/privacy/'
};

export const getBrandedPrivacyLink = (brand: string): string => {
  return privacyLinks[brand];
};