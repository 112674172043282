import { action, computed, observable } from 'mobx';
import { type SyntheticEvent } from 'react';
import Merchant from './merchant';
import Contact from './contact';
import Invoice from './invoice';
import BankAccountLimit from './bankAccountLimit';
import { makePortalRequest, newPortalLink, payInvoices, requestToken, paymentInfoRequest, featureRequest } from '../utils/requester';
import PaymentDataStore from './paymentDataStore';
import {
  type BankAccountLimitsResponse,
  type CamelizedOneTimeToken,
  InvoicePaymentTypes,
  type InvoiceResponse,
  type Notice,
  type PaymentInfoRequest,
  type PortalResponse,
  type PaymentInfoResponse,
  UISteps, UITransitionActions,
  type InvoiceAllocation,
  type Feature,
  type AMPErrorResponse,
  type InvoicePaymentResponseCollection,
  type InvoicePaymentRequest,
} from '../types';
import { CountrySubdivisionStore } from './countrySubdivisionStore';
import { consoleLogger } from '../utils/logger';
import { extractGatewayErrorMessage } from '../utils/extract-gateway-error-message';
import { extractContactHash, hasContactHash, hasPreviewMode, hasPaymentInfoRequest } from '../utils/url-params-utils';
import { previewDataFromCookie } from '../utils/cookie-parser-utils';
import ApplicationFSM from './applicationFSM';
import { getCurrentBrand } from '../utils/brand-parser';

const DEFAULT_BANK_ACCOUNT_LIMIT = 500000;
const DEFAULT_LOAN_ACCOUNT_LIMIT = 1750000;

function sumAmounts(invoices: Invoice[]): number {
  return invoices.reduce((sum: number, invoice: Invoice) => (sum + invoice.amountDue), 0);
}

export class AppStore {
  merchant: Merchant;
  contact: Contact;
  invoices: Invoice[];
  paymentDataStore: PaymentDataStore;
  countrySubdivisionStore: CountrySubdivisionStore;
  emailAddresses?: string[];
  contactHash: string;
  previewMode = false;
  hasError = false;
  gatewayUrl: string;
  currentBrand: string;
  bankAccountLimits: BankAccountLimit[];
  @observable notice: Notice = { show: false };
  @observable requestingNewPortalLink = false;
  @observable submittingPayment = false;
  @observable submittingPaymentInfoRequest = false;
  @observable paymentInfoRequestAuthorized = false;
  @observable currentStep: UISteps = UISteps.Initial;
  @observable paymentInfoResponse: PaymentInfoResponse | null = null;
  @observable failureMessage = 'Error loading the page. Please try again.';
  @observable eCheckSelected = false;
  @observable testMode = false;
  @observable prepaymentId = '';
  @observable portalJWT = '';
  @observable upfEnabled = false;
  @observable upfSurchargeEnabled = false;

  constructor() {
    this.paymentDataStore = new PaymentDataStore(this);
    this.countrySubdivisionStore = new CountrySubdivisionStore(this.paymentDataStore.country);
  }

  init(props: any): void {
    this.setCurrentBrand();
    if (hasContactHash(props)) {
      const contactHash = extractContactHash(props);
      const isPaymentInfoRequest = hasPaymentInfoRequest(props);
      this.fetchPortalData(contactHash, isPaymentInfoRequest);
    } else if (hasPreviewMode(props)) {
      this.setupPreviewData();
    } else {
      this.transitionToNewStep(UITransitionActions.InitialDataFetchFailure);
    }
  }

  @action setCurrentBrand(): void {
    this.currentBrand = getCurrentBrand(window.location.hostname);
  }

  @action setPrepaymentId(prepaymentId: string): void {
    this.prepaymentId = prepaymentId;
  }

  @computed get sourceId(): string {
    return `${this.gatewayUrl}/payments/${this.prepaymentId}`;
  }

  @computed get invoiceAllocations(): InvoiceAllocation[] {
    const allocations: InvoiceAllocation[] = this.selectedInvoices.map((i: Invoice) => ({
      invoiceId: i.id,
      amount: i.amountDue
    }));
    return allocations;
  }

  @action setTestMode(testMode: boolean): void {
    this.testMode = testMode;
  }

  @action setupPreviewData(): void {
    try {
      const portalResponse: PortalResponse = previewDataFromCookie();
      this.merchant = Merchant.fromJSON(portalResponse.merchant);
      this.setInvoices(portalResponse.invoices);
      this.previewMode = true;
    } catch (e) {
      consoleLogger('Something went wrong', e);
      this.transitionToNewStep(UITransitionActions.InitialDataFetchFailure);
    }
  }

  @action.bound transitionToNewStep(transitionAction: UITransitionActions): void {
    this.currentStep = ApplicationFSM.transitionToState(this.currentStep, transitionAction);
  }

  @action async fetchPortalData(contactHash: string, isPaymentInfoRequest: boolean): Promise<void> {
    this.contactHash = contactHash;
    this.transitionToNewStep(UITransitionActions.FetchInitialData);
    let portalResponse;
    try {
      portalResponse = await makePortalRequest(this.contactHash);
      this.portalJWT = portalResponse.token;
      this.emailAddresses = portalResponse.emailAddresses;
      this.merchant = Merchant.fromJSON(portalResponse.merchant);
      this.setInvoices(portalResponse.invoices);
      this.setBankAccountLimits(portalResponse.bankAccountLimits);
      if (portalResponse.contact) {
        this.contact = Contact.fromJSON(portalResponse.contact);
      }
      if (portalResponse.requiredPaymentFields) {
        // TODO - we're setting the required fields for invoices that can be paid by credit card only
        this.paymentDataStore.setCreditCardValidationRules(portalResponse.requiredPaymentFields.creditCard);
        this.paymentDataStore.setAchValidationRules(portalResponse.requiredPaymentFields.bankAccount);
      }
      if (portalResponse.gatewayUrl) {
        this.gatewayUrl = portalResponse.gatewayUrl;
      }
      const featureResponse = await featureRequest(this.portalJWT, this.gatewayUrl);
      const features = featureResponse.map((feature: Feature) => feature.name);
      this.upfEnabled = features.includes('upf_invoicing');
      this.upfSurchargeEnabled = features.includes('upf_invoicing_surcharge');
    } catch (e) {
      consoleLogger('Something went wrong', e);
      if (e.message === 'Hash has expired and is non-renewable') {
        const errMessage = 'This link to enter payment information has expired. Please contact your service provider to get a new link.';
        this.setFailureMessage(errMessage);
      }
      this.transitionToNewStep(UITransitionActions.InitialDataFetchFailure);
    } finally {
      if (portalResponse?.expiredLink) {
        this.setNotice('error', 'Your payment link has expired.');
        this.transitionToNewStep(UITransitionActions.InitialDataFetchSuccessExpired);
      } else {
        if (isPaymentInfoRequest && portalResponse && !portalResponse.infoRequests) {
          this.setFailureMessage(`A payment method was already saved using this link. If you need to save a second payment method, please contact ${this.merchant.name} to get a new link.`);
          this.transitionToNewStep(UITransitionActions.InitialDataFetchFailure);
        } else if (isPaymentInfoRequest) {
          this.paymentDataStore.setValue('paymentType', 'creditCard');
          this.transitionToNewStep(UITransitionActions.InitialDataFetchPaymentInfoRequest);
        } else {
          this.transitionToNewStep(UITransitionActions.InitialDataFetchSuccess);
        }
      }
    }
  }

  @action setInvoices(invoices: InvoiceResponse[]): void {
    // The filter must be after the map because the supportedPaymentType values get camelized on Invoice.fromJSON
    this.invoices = invoices.map((i: InvoiceResponse) => Invoice.fromJSON(i));
  }

  @action setBankAccountLimits(limits: BankAccountLimitsResponse[]): void {
    this.bankAccountLimits = limits.map((i: BankAccountLimitsResponse) => BankAccountLimit.fromJSON(i));
  }

  @action setNotice(type: string, message: string): void {
    this.notice = { show: true, type, message };
  }

  @action setFailureMessage(message: string): void {
    this.failureMessage = message;
  }

  @action.bound async requestNewPortalLink() {
    this.requestingNewPortalLink = true;
    try {
      await newPortalLink(this.contactHash);
      this.requestNewPortalLinkSuccess();
    } catch (e) {
      this.requestNewPortalLinkError(e);
    }
  }

  @action.bound requestNewPortalLinkSuccess(): void {
    this.requestingNewPortalLink = false;
    this.setNotice('info', 'You will be sent a fresh link via email.');
  }

  @action.bound requestNewPortalLinkError(e: Error): void {
    consoleLogger(e);
    this.requestingNewPortalLink = false;
    this.setNotice('error', e.message);
  }

  @action.bound dismissNotice(): void {
    this.notice = { show: false };
  }

  // Invoice Actions
  @computed get allSelected(): boolean {
    if (!this.invoices) { return false; }

    return this.allowedInvoices.every(i => i.selected);
  }

  @action.bound removeSelected(): void {
    this.invoices = this.invoices.filter((i: Invoice) => this.selectedInvoices.indexOf(i) === -1);
  }

  @computed get selectedInvoicesAvailablePaymentTypes(): string[] {
    const selectMany = this.selectedInvoices.map((i: Invoice) => i.supportedPaymentTypes);
    const intersectionOfPaymentTypes = selectMany.length > 0 ? selectMany.reduce((a, b) => {
      return a.filter(c => b.indexOf(c) !== -1);
    }) : [];
    const paymentTypeOrder = ['creditCard', 'bankAccount', 'loan'];
    return intersectionOfPaymentTypes.sort((pt1: string, pt2: string) => {
      if (pt1 === pt2) {
        return 0;
      } else if (paymentTypeOrder.indexOf(pt1) > paymentTypeOrder.indexOf(pt2)) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  getInvoiceAchLimit(invoice: Invoice): number {
    const bankAccount = this.bankAccountLimits.find(ba => ba.id === invoice.bankAccountId);
    return (bankAccount ? bankAccount.achLimit : null) ?? DEFAULT_BANK_ACCOUNT_LIMIT;
  }

  @computed get selectedInvoicesLowestAchLimit(): number {
    const limits = this.selectedInvoices.map(i => this.getInvoiceAchLimit(i));
    return Math.min(...limits);
  }

  @computed get isPaymentAmountUnderAchLimit(): boolean {
    return this.paymentAmount <= this.selectedInvoicesLowestAchLimit;
  }

  getInvoiceLoanLimit(invoice: Invoice): number {
    const bankAccount = this.bankAccountLimits.find(ba => ba.id === invoice.bankAccountId);
    return (bankAccount ? bankAccount.loanLimit : null) ?? DEFAULT_LOAN_ACCOUNT_LIMIT;
  }

  @computed get selectedInvoicesLowestLoanLimit(): number {
    const limits = this.selectedInvoices.map(i => this.getInvoiceLoanLimit(i));
    return Math.min(...limits);
  }

  @computed get isPaymentAmountUnderLoanLimit(): boolean {
    return this.paymentAmount <= this.selectedInvoicesLowestLoanLimit;
  }

  @computed get selectedInvoicesPaymentTypesCompatible(): boolean {
    return this.selectedInvoicesAvailablePaymentTypes.length > 0;
  }

  invoiceArrayCurrencyTypes(invoicesArray: Invoice[]): string[] {
    const currencySet: Record<string, boolean> = {};

    invoicesArray.forEach((i: Invoice) => {
      if (i.currency) {
        currencySet[i.currency] = true;
      }
    });

    return Object.keys(currencySet);
  }

  @computed get invoiceCurrencyTypes(): string[] {
    return this.invoiceArrayCurrencyTypes(this.invoices);
  }

  @computed get hasMultipleCurrencies(): boolean {
    return this.invoiceCurrencyTypes.length > 1;
  }

  @computed get selectedInvoicesCurrencyTypes(): string[] {
    return this.invoiceArrayCurrencyTypes(this.selectedInvoices);
  }

  @computed get currencyDisplayHelper(): string {
    if (this.selectedInvoicesCurrencyTypes.length > 0) return this.selectedInvoicesCurrencyTypes[0];
    return this.invoices[0]?.currency || 'USD';
  }

  @computed get selectedInvoicesCurrencyTypesCompatible(): boolean {
    return this.selectedInvoicesCurrencyTypes.length < 2;
  }

  @computed get paymentAmount(): number {
    return sumAmounts(this.selectedInvoices);
  }

  @computed get outstandingBalance(): number {
    return sumAmounts(this.notSelectedInvoices);
  }

  @computed get selectedInvoices(): Invoice[] {
    return this.allowedInvoices.filter((i: Invoice) => i.selected);
  }

  @computed get notSelectedInvoices(): Invoice[] {
    return this.allowedInvoices.filter((i: Invoice) => !i.selected);
  }

  @computed get hasSelectedAnInvoice(): boolean {
    if (!this.invoices) { return false; }

    return this.invoices.some(i => i.selected);
  }

  @computed get hasAttachments(): boolean {
    return this.invoices.some((i: Invoice) => i.invoiceAttachments.length > 0);
  }

  @computed get isPaymentInfoRequestView(): boolean {
    return this.currentStep === UISteps.PaymentInfoRequest ||
      this.currentStep === UISteps.PaymentInfoRequestSuccess;
  }

  @computed get allowedInvoices(): Invoice[] {
    if (!this.invoices) { return []; }

    return this.invoices.filter((i: Invoice) => i.amountDue > 0);
  }

  @action.bound toggleAllInvoices(upfEnabled = false): void {
    if (!this.invoices) { return; }

    const uniqueBankAccounts = new Set(this.allowedInvoices.map((i: Invoice) => i.bankAccountIdBase62));
    if (upfEnabled && uniqueBankAccounts.size > 1) {
      this.setNotice('error', 'Unable to select all invoices. Because of your provider\'s settings, some invoices cannot be paid together.');
      return;
    }

    const initialState = this.allSelected;

    this.allowedInvoices.forEach((i: Invoice) => {
      i.selected = !initialState;
    });

    this.paymentDataStore.toggleAmountSelected(this.hasSelectedAnInvoice);
  }

  @action.bound toggleSelectedInvoice(invoice: Invoice, upfEnabled = false): void {
    this.hasError = false;
    if (upfEnabled && this.selectedInvoices.some(i => i.bankAccountIdBase62 !== invoice.bankAccountIdBase62)) {
      this.setNotice('error', 'Unable to select this invoice. Because of your provider\'s settings, some invoices cannot be paid together and need to be paid individually. If you want to pay this invoice, please change your selection.');
      return;
    }
    invoice.toggleSelected();
    this.paymentDataStore.toggleAmountSelected(this.hasSelectedAnInvoice);

    if (this.selectedInvoicesPaymentTypesCompatible) {
      this.paymentDataStore.setValue('paymentType', this.selectedInvoicesAvailablePaymentTypes[0]);
    } else {
      // error message
      if (this.hasSelectedAnInvoice) {
        this.hasError = true;
        this.setNotice('error', 'Some of these bills do not share any payment methods and cannot be paid at the same time. Please pay them separately.');
      }
    }

    if (this.selectedInvoicesCurrencyTypesCompatible) {
      this.paymentDataStore.setValue('currency', this.selectedInvoicesCurrencyTypes[0]);
    } else {
      // error message
      if (this.hasSelectedAnInvoice) {
        this.hasError = true;
        this.setNotice('error', 'Some of these bills do not share the same currency. Please pay them separately.');
      }
    }
  }

  @action.bound makePayment(e: SyntheticEvent<HTMLInputElement>): Promise<void> {
    e.preventDefault();
    this.paymentDataStore.validateModel();
    if (!this.paymentDataStore.valid) {
      this.paymentDataStore.touchAllFields(true);
      return Promise.reject(new Error('Fields are invalid'));
    }
    this.startSubmittingPayment();
    return this.makeTokenRequest()
      .then(
        (token: CamelizedOneTimeToken): void => {
          const { currency } = this.paymentDataStore;
          const invoiceIds = this.selectedInvoices.map(i => i.id);
          const invoicePaymentReq: InvoicePaymentRequest = {
            paymentType: InvoicePaymentTypes[token.type],
            currency: currency || 'USD',
            amount: this.paymentAmount,
            tokenId: token.id,
            invoiceIds
          };
          payInvoices(this.contactHash, invoicePaymentReq)
            .then(
              (invoiceResponseCollection: InvoicePaymentResponseCollection): void => {
                // if invoice response status is "authorized", request went through
                // if it failed, the status will be "transaction_failed"
                const [{ status, failureMessage }] = invoiceResponseCollection;
                if (status === 'authorized') {
                  this.removeSelected();
                  this.paymentDataStore = new PaymentDataStore(this);
                  this.stopSubmittingPayment();
                  this.transitionToNewStep(UITransitionActions.MadeSuccessfulPayment);
                } else {
                  this.setNotice('error', <string> failureMessage);
                  this.stopSubmittingPayment();
                }
              },
              (): void => {
                // TODO - generate a better error message
                this.setNotice('error', 'Something went wrong, please contact the vendor.');
                this.stopSubmittingPayment();
              }
            );
        },
        (resp: AMPErrorResponse): void => {
          const { messages: [message] } = resp;
          const noticeMessage = extractGatewayErrorMessage(message);
          this.setNotice('error', noticeMessage);
          this.stopSubmittingPayment();
        }
      );
  }

  @action async makeTokenRequest(): Promise<CamelizedOneTimeToken> {
    const { publicKey } = this.merchant;
    const emailAddresses = this.contact.emailAddresses ? this.contact.emailAddresses : [];
    let email;
    if (emailAddresses.length > 0) { email = emailAddresses[0].address; }

    const paymentInformation = { ...this.paymentDataStore.oneTimeTokenData };

    if (email) { paymentInformation.email = email; }

    return await requestToken(this.gatewayUrl, publicKey, paymentInformation);
  }

  @action startSubmittingPayment(): void {
    this.submittingPayment = true;
  }
  @action stopSubmittingPayment(): void {
    this.submittingPayment = false;
  }

  @action startSubmittingPaymentInfoRequest(): void {
    this.submittingPaymentInfoRequest = true;
  }

  @action stopSubmittingPaymentInfoRequest(): void {
    this.submittingPaymentInfoRequest = false;
  }

  @action setPaymentInfoResponse(response: PaymentInfoResponse): void {
    this.paymentInfoResponse = response;
  }

  @action.bound async makePaymentInfoRequest(e: SyntheticEvent<HTMLInputElement>): Promise<void> {
    e.preventDefault();
    this.paymentDataStore.validateModel();
    if (!this.paymentDataStore.valid) {
      this.paymentDataStore.touchAllFields(true);
      await Promise.reject(new Error('Fields are invalid')); return;
    }
    this.startSubmittingPaymentInfoRequest();
    try {
      const token = await this.makeTokenRequest();
      const paymentInfo: PaymentInfoRequest = {
        paymentType: InvoicePaymentTypes[token.type],
        authorizedUses: ['all'],
        oneTimeToken: token.id
      };
      await paymentInfoRequest(this.contactHash, paymentInfo).then(
        (paymentInfoResponse: PaymentInfoResponse) => {
          this.setPaymentInfoResponse(paymentInfoResponse);
          this.transitionToNewStep(UITransitionActions.MadeSuccessfulPaymentInfoRequest);
          this.stopSubmittingPaymentInfoRequest();
        }).catch(
        (resp): void => {
          const errMessage = resp.message === 'state_conflict'
            ? `A payment method was already saved using this link. If you need to save a second payment method, please contact ${this.merchant.name} to get a new link.`
            : resp.message;
          this.setNotice('error', errMessage);
          this.stopSubmittingPaymentInfoRequest();
        }
      );
    } catch (e) {
      const { messages: [message] } = e;
      const noticeMessage = extractGatewayErrorMessage(message);
      this.setNotice('error', noticeMessage);
      this.stopSubmittingPaymentInfoRequest();
    }
  }

  @action.bound handleAuthorizationChange(event: React.FormEvent<HTMLInputElement>): void {
    this.paymentInfoRequestAuthorized = event.currentTarget.checked;
  }

  @action.bound togglePaymentDisclaimer(tabSelected: string) {
    tabSelected === 'bankAccount' ? this.eCheckSelected = true : this.eCheckSelected = false;
  }
}

export default new AppStore();
