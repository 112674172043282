import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

const ContactRequestInformation = inject('appStore')(observer(({ appStore: { contact: model } }: any) => {
  return (
    <div>
      <div className="form-header">
        <h3>Request created for</h3>
      </div>
      <Row>
        <Col lg={12}>
          {(model.firstName || model.lastName) &&
            <div>
              {model.firstName && <span>{model.firstName} </span>}
              {model.lastName && <span>{model.lastName}</span>}
            </div>
          }
          <div>{model.preferredEmail}</div>
        </Col>
      </Row>
    </div>
  );
}));

export default ContactRequestInformation;
