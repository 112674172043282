import * as React from 'react';
import { Col, Grid } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import MerchantHeader from './MerchantHeader';
import { Success } from './Icons';
import { UITransitionActions } from '../types';

const PaymentSuccess = inject('appStore')(observer(({ appStore }: any) => {

  return (
    <div className="payment-success">
      <MerchantHeader />

      <Grid fluid>
        <Col lg={6} lgOffset={3} className="text-center">
          <div className="payment-success-container">
            <p className="legend">Thank You!</p>
            <Success />
            <div className="text-content">
              <p>Your payment is being processed.</p>
              <p>A receipt for this transaction is on the way.</p>
            </div>

            { appStore.invoices.length > 0
              ? (
                <div className="payment-success-footer">
                  <button
                    className="button"
                    type="button"
                    onClick={() => {
                      appStore.transitionToNewStep(UITransitionActions.BackToInvoices);
                    }}
                  >
                  Back To Outstanding Bills
                  </button>
                </div>
              )
              : null}
          </div>
        </Col>
      </Grid>

    </div>
  );
}));

export default PaymentSuccess;
