import Country, { type Subdivision } from 'country-subdivisions';
import { action, computed, observable } from 'mobx';
import { capitalize } from '../utils/formatters';

const INITIAL_COUNTRY_CODE = 'US';

export class CountrySubdivisionStore {
  @observable selectedCountry: Country | undefined;

  constructor(countryCode: string = INITIAL_COUNTRY_CODE) {
    this.setCountry(countryCode);
  }

  @action setCountry(countryCode: string): void {
    // TODO only change the country if it's a different one
    this.selectedCountry = Country.getCountry(countryCode);
  }

  @computed get subdivisions(): Subdivision[] | undefined {
    if (!this.selectedCountry) { return; }

    // Sort them by name?
    return this.selectedCountry.subdivisions();
  }

  @computed get hasSubdivisions(): boolean {
    if (!this.subdivisions) { return false; }

    return this.subdivisions.length > 0;
  }

  @computed get subdivisionTypeName(): string | undefined {
    if (!this.selectedCountry) { return; }

    const subdivisionTypeName: string = this.selectedCountry
      .subdivisionTypes()
      .general()
      .map(sdt => sdt.name())[0];

    return capitalize(subdivisionTypeName);
  }
}
