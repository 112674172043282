import * as React from 'react';
import { Checkmark } from './Icons';

interface CheckboxProps {
  checked: boolean
  className?: string
  disabled?: boolean
  labelText?: string
  checkboxID: string
  onChange: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxID,
  checked,
  className='ap-checkbox-portal',
  disabled = false,
  labelText='',
  onChange
}:  CheckboxProps) => (
  <span className={className} >
    <input
      type="checkbox"
      id={checkboxID}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <label htmlFor={checkboxID}>
      {labelText}
      <Checkmark/>
    </label>
  </span>
);

export default Checkbox;
