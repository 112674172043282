import * as React from 'react';
import { inject } from 'mobx-react';
import { formatPhone } from '../utils/formatters';

const MerchantHeader = inject('appStore')(({ appStore: { merchant } }: any) => {
  return (
    <header>
      <div className="header__merchant-logo">
        {merchant.logoUrl
          ? (
            <img
              src={merchant.logoUrl}
              alt="Merchant Logo"
            />
          )
          : (
            <div className="header__img-placeholder hidden-xs" />
          )}
      </div>

      <div className="header__merchant-details hidden-xs">
        <address>
          <p className="merchant-name">{merchant.name}</p>
          <p>{merchant.address}</p>
          <p>{merchant.additionalAddress}</p>
          <p>{merchant.city}, {merchant.state} {merchant.postalCode}</p>
        </address>

        <address>
          <p className="phone-number">
            <a href={`tel:${merchant.phone as string}`}>{formatPhone(merchant.phone)}</a>
          </p>
          <p className="merchant-misc-info">
            <a href={`mailto:${merchant.supportEmail as string}`}>{merchant.supportEmail}</a>
          </p>
          <p className="merchant-misc-info">
            <a href={merchant.website}>{merchant.website}</a>
          </p>
        </address>
      </div>

    </header>
  );
});

export default MerchantHeader;
