import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import PaymentRequestInformation from './PaymentRequestInformation';
import BillingRequestInformation from './BillingRequestInformation';
import ContactRequestInformation from './ContactRequestInformation';
import AuthorizationCheckbox from './AuthorizationCheckbox';
import MerchantSidebar from './MerchantSidebar';
import PaymentRequestInfoSuccess from './PaymentRequestInfoSuccess';
import { UISteps } from '../types';

const PaymentRequestForm = inject('appStore')(observer(({
  appStore: {
    submittingPaymentInfoRequest,
    previewMode,
    makePaymentInfoRequest,
    paymentDataStore,
    paymentInfoRequestAuthorized,
    currentStep
  }

}: any) => {
  return (
    <div className="payment-info-request-step">
      <form
        className="payment-info-request-form"
        onSubmit={makePaymentInfoRequest}
      >
        <Row>
          <Col lg={4}>
            <MerchantSidebar />
          </Col>
          <Col lg={8}>
            {currentStep === UISteps.PaymentInfoRequest &&
              <div>
                <div className="form-header">
                  <h2>Please Enter Your Information</h2>
                  <h5>For your convenience, our firm offers the option to securely store your payment information and simplify your billing experience.</h5>
                </div>
                <ContactRequestInformation />
                <PaymentRequestInformation />
                <BillingRequestInformation />
                <AuthorizationCheckbox />
              </div>
            }
            {currentStep === UISteps.PaymentInfoRequestSuccess &&
              <div>
                <PaymentRequestInfoSuccess />
              </div>
            }
          </Col>
        </Row>
        {currentStep === UISteps.PaymentInfoRequest &&
          <Row>
            <Col lg={4} />
            <Col lg={8}>
              <button
                className="button"
                type="submit"
                disabled={submittingPaymentInfoRequest || !paymentDataStore.valid || !paymentInfoRequestAuthorized || previewMode}
              >
                Save My Payment Information
              </button>
            </Col>
          </Row>
        }
      </form>
    </div>
  );
}));

export default PaymentRequestForm;
