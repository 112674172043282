import { type Currency } from 'dinero.js';
export interface PortalResponse {
  invoices: InvoiceResponse[]
  infoRequests?: InfoRequestResponse[]
  contact?: ContactResponse
  merchant: MerchantResponse
  expiredLink?: boolean
  emailAddresses?: string[]
  requiredPaymentFields: RequiredPaymentFields
  gatewayUrl?: string
  bankAccountLimits: BankAccountLimitsResponse[]
  token: string
}

export interface RequiredPaymentFields {
  creditCard: string[]
  bankAccount: string[]
}

export interface InvoiceResponse {
  id: string
  invoiceNumber: string
  invoiceDate: string
  dueDate: string
  totalAmount: number
  amountDue: number
  reference: string
  supportedPaymentTypes: string[]
  invoiceAttachments: InvoiceAttachedFile[]
  currency: string
  bankAccountId: string
  bankAccountIdBase62: string
  testMode: boolean
}

export interface InfoRequestResponse {
  contactId: string
  created: object
  id: string
  message: object
  modified: object
  ownerId: string
  status: string
  testMode: boolean
  type: string
}

export enum GatewayPaymentTypes {
  BankAccount = 'bank',
  CreditCard = 'card'
}

export enum SupportedPaymentTypes {
  CreditCard = 'creditCard',
  BankAccount = 'bankAccount',
  Loan = 'loan'
}

export enum AccountHolderType {
  Individual = 'individual',
  Business = 'business'
}

export enum InvoicePaymentTypes {
  Card = 'credit_card',
  Bank = 'bank_account',
  card = 'credit_card',
  bank = 'bank_account',
}

export enum PaymentFieldTypes {
  Number = 'number',
  ExpMonth = 'expMonth',
  ExpYear = 'expYear',
  Country = 'country',
  CVV = 'cvv',
  City = 'city',
  Address1 = 'address1',
  Address2 = 'address2',
  Name = 'name',
  State = 'state',
  PostalCode = 'postalCode',
  Email = 'email',
  GivenName = 'givenName',
  Surname = 'surname',
  AccountNumber = 'accountNumber',
  RoutingNumber = 'routingNumber',
  AccountHolderType = 'accountHolderType'
}

export enum ContactFieldTypes {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'emailAddresses'
}

export enum AchFieldTypes {
  GivenName = 'givenName',
  Surname = 'surname',
  AccountName = 'accountName',
  AccountNumber = 'accountNumber',
  AccountType = 'accountType',
  RoutingNumber = 'routingNumber',
}

export enum UISteps {
  Initial = 0,
  Invoices = 1,
  Payment = 2,
  PaymentSuccess = 3,
  ExpiredLink = 4,
  Loading = 5,
  InitialDataFetchError = 6,
  PaymentInfoRequest = 7,
  PaymentInfoRequestSuccess = 8
}

export enum UITransitionActions {
  FetchInitialData = 'FETCH_INITIAL_DATA',
  InitialDataFetchSuccess = 'INITIAL_DATA_FETCH_SUCCESS',
  InitialDataFetchSuccessExpired = 'INITIAL_DATA_FETCH_SUCCESS_EXPIRED',
  InitialDataFetchFailure = 'INITIAL_DATA_FETCH_FAILURE',
  ShowPaymentForm = 'SHOW_PAYMENT_FORM',
  ShowInvoices = 'SHOW_INVOICES',
  MadeSuccessfulPayment = 'MADE_SUCCESSFUL_PAYMENT',
  BackToInvoices = 'BACK_TO_INVOICES',
  InitialDataFetchPaymentInfoRequest = 'INITIAL_DATA_FETCH_PAYMENT_INFO_REQUEST',
  MadeSuccessfulPaymentInfoRequest = 'MADE_SUCCESSFUL_PAYMENT_INFO_REQUEST',
  MadeUnsuccessfulPaymentInfoRequest = 'MADE_UNSUCCESSFUL_PAYMENT_INFO_REQUEST'
}

export interface ContactResponse {
  id: string
  deleted: boolean
  testMode: boolean
  contactCode: string
  ownerId: string
  preferredCurrency: string
  addresses: AddressResponse[]
  phoneNumbers: PhoneNumberResponse[]
  emailAddresses: EmailAddressResponse[]
  firstName: string
  lastName: string
}

export interface AddressResponse {
  id: string
  line1: string
  city: string
  stateOrProvince: string
  postalCode: string
  countryCode: string
  preferred: boolean
}

export interface PhoneNumberResponse {
  id: string
  number: string
  preferred: boolean
}

export interface EmailAddressResponse {
  id: string
  address: string
  preferred: boolean
}

export interface MerchantResponse {
  name: string
  address: string
  additionalAddress: string
  city: string
  state: string
  postalCode: string
  country: string
  website: string
  supportEmail: string
  emailSuccessTo: string
  emailFailureTo: string
  referenceLabel: string
  publicKey: string
  phone: string
  supportPhone: string
  logoUrl: string
  disclaimerHtml: string
}

export interface Notice {
  show: boolean
  type?: string
  message?: string
}

export interface AMPErrorResponse {
  messages: AMPErrorMessage[]
}

export interface AMPErrorMessage {
  code: string
  facility: string
  level: string
  message: string
  subCode?: string
  context?: string
}

// Need to specify in here because the type from the tokenization library is in snake case
// we want to use camel case
export interface CamelizedOneTimeToken {
  id?: string
  type: 'card' | 'bank'
  number?: string
  expMonth?: number | string
  expYear?: number | string
  cvv?: string
  name?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  postalCode?: number | string
  country?: string
  email?: string
  reference?: string
}

export interface InvoicePaymentRequest {
  paymentType: string
  currency: string
  amount: number
  tokenId?: string
  invoiceIds: string[]
}

export type InvoicePaymentResponseCollection = InvoicePaymentResponse[];

export interface InvoicePaymentResponse {
  id: string
  amount: string
  transactionId?: string
  paymentType: string
  contactId: string
  ownerId: string
  status?: string
  failureMessage?: string
}

export interface InvoiceAttachedFile {
  id: string
  created: object
  modified: object
  contactXID: string
  fileName: string
}

export interface PaymentInfoRequest {
  authorizedUses: string[]
  paymentType: string
  oneTimeToken?: string
}

export interface PaymentInfoResponse {
  accountNumber: string
  address: object
  allowFuturePayments: boolean
  authorizedUses: string[]
  cardType: string
  created: object
  expirationMonth: number
  expirationYear: number
  id: string
  modified: object
  payeeId: string
  paymentType: string
  preferred: boolean
  testMode: boolean
  type: string
}

export interface BankAccountLimitsResponse {
  id: string
  idBase62: string
  achLimit: number
  cardLimit: number
  loanLimit: number
}

export interface InvoiceAllocation {
  invoiceId: string,
  amount: number
}

export interface Feature {
  type: 'feature'
  id: string
  name: string
}

export interface CurrencyEnum {
  'en-CA': Currency,
  'en-US': Currency,
  'fr-CA': Currency
}

export type Locale = string;
