import * as React from 'react';
import { inject } from 'mobx-react';
import { formatPhone } from '../utils/formatters';
import affinipay from '../images/powered-by-affinipay-logo.png';
import lawpay from '../images/powered-by-lawpay-logo.png';
import cpacharge from '../images/powered-by-cpacharge-logo.png';
import designpay from '../images/powered-by-designpay-logo.svg';
import clientpay from '../images/powered-by-clientpay-logo.svg';
import medpay from '../images/powered-by-medpay-logo.svg';
import visaIcon from '../images/icon-payment-method-visa.svg';
import mastercardIcon from '../images/icon-payment-method-mastercard.svg';
import amexIcon from'../images/icon-payment-method-american_express.svg';
import discoverIcon from '../images/icon-payment-method-discover.svg';
import bankIcon from '../images/icon-payment-method-bank.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UISteps } from '../types';

type LogosEnum = {
  affinipay: string,
  lawpay: string,
  cpacharge: string,
  designpay: string,
  clientpay: string,
  medpay: string
}
const logos: LogosEnum = { affinipay, lawpay, cpacharge, designpay, clientpay, medpay };

const Footer = inject('appStore')(({ appStore: { currentBrand, isPaymentInfoRequestView, merchant, upfEnabled, currentStep } }: any) => {
  const { upfEnabledLd } = useFlags();
  const isUpfEnabled = upfEnabled || upfEnabledLd;
  const hideCardLogos = currentStep === UISteps.Payment && isUpfEnabled;

  return (
    <footer className={isPaymentInfoRequestView ? 'card-vault-padding' : ''}>
      <div className="merchant-info hidden-sm hidden-md hidden-lg text-center">
        <address>
          <p className="merchant-name">{merchant.name}</p>
          <p>{merchant.address}</p>
          {merchant.additionalAddress && <p>{merchant.additionalAddress}</p>}
          <p>{merchant.city}, {merchant.state} {merchant.postalCode}</p>
        </address>

        <address>
          <p>
            <a href={`tel:${merchant.phone as string}`}>{formatPhone(merchant.phone)}</a>
          </p>
          <p>
            <a href={`mailto:${merchant.supportEmail as string}`}>{merchant.supportEmail}</a>
          </p>
          <p>
            <a href={merchant.website}>{merchant.website}</a>
          </p>
        </address>
      </div>
      <hr/>
      <div className="footer">
        <div className="footer-logo-container">
          <div>
            <img
              className="footer-logo"
              src={logos[currentBrand as keyof LogosEnum] as string}
              alt={`Payments By ${currentBrand as string}`}
            />
          </div>
          <div className="disclaimer-text">
            Our system uses SSL encryption technology, which is the <br />
            same technology banks use to protect sensitive information
          </div>
        </div>
        {!hideCardLogos &&
          <div className="card-logos">
            <img src={visaIcon} alt="Visa Icon" />
            <img src={mastercardIcon} alt="MasterCard Icon" />
            <img src={amexIcon} alt="Amex Icon" />
            <img src={discoverIcon} alt="Discover Icon" />
            <img src={bankIcon} alt="Bank Icon" />
          </div>
        }
      </div>
    </footer>
  );
});

export default Footer;
