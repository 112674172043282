import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import Jsona from 'jsona';
const dataFormatter = new Jsona();
import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  type AMPErrorMessage,
  type AMPErrorResponse,
  type CamelizedOneTimeToken,
  type PortalResponse,
  type PaymentInfoRequest,
  type PaymentInfoResponse,
  type Feature,
  type InvoicePaymentRequest,
  type InvoicePaymentResponseCollection
} from '../types';
import {
  init as initializeTokenLibrary,
  createToken,
  type OneTimeToken
} from 'affinipay-token';
import UnprocessableEntityError from './unprocessable-entity-error';

function parseErrorMessages(json: AMPErrorResponse): string {
  return json.messages.map((m: AMPErrorMessage) => {
    if (m.code === 'state_conflict') {
      return m.code;
    } else if (m.message) {
      return m.message;
    }
    return m.code;
  })[0];
}

function handleResponseErrors(e: AxiosError<AMPErrorResponse>): void {
  if (!e.response) {
    return;
  }

  const messages = camelizeKeys(e.response.data) as AMPErrorResponse;
  switch (e.response.status) {
  case 400:
  case 401:
  case 404:
  case 409:
  case 500: {
    const message = parseErrorMessages(messages);
    throw new Error(message);
  }
  case 422:
    throw new UnprocessableEntityError(messages.messages);
  default:
    throw e;
  }
}

const determineGatewayUrl = (gatewayUrl: string) => {
  const env = process.env.REACT_APP_ENV ?? 'development';
  return ['production', 'staging'].includes(env) ? gatewayUrl : 'http://localhost:30080';
};

export async function makePortalRequest(contactHash: string): Promise<PortalResponse> {
  // TODO - remove http request mock
  if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_MOCK_REQUEST) {
    const responseFile = await import(process.env.REACT_APP_SAMPLE_RESPONSE_FILE as string);
    const mockData = camelizeKeys(responseFile);
    return await new Promise(resolve => {
      setTimeout(() => { resolve(mockData as PortalResponse); }, 500);
    });
  } else {
    const url = `/portal/${contactHash}`;
    return await axios.get(url)
      .then((response: AxiosResponse) => camelizeKeys(response.data) as PortalResponse)
      .catch((e: AxiosError<AMPErrorResponse>) => {
        handleResponseErrors(e);
        throw e;
      });
  }
}

export async function newPortalLink(contactHash: string): Promise<void> {
  const url = `/portal/${contactHash}/renew`;
  await axios.post(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(async () => { await Promise.resolve(); })
    .catch((e: AxiosError<AMPErrorResponse>) => {
      handleResponseErrors(e);
      throw e;
    });
}

export async function requestToken(gatewayUrl: string, publicKey: string, ccData: CamelizedOneTimeToken): Promise<CamelizedOneTimeToken> {
  const tokenUrl = `${determineGatewayUrl(gatewayUrl)}/v1/tokens`;
  const decamelizedCCData: OneTimeToken = decamelizeKeys(ccData) as OneTimeToken;
  initializeTokenLibrary({ tokenUrl, publicKey });
  return await createToken(decamelizedCCData)
    .then((data: OneTimeToken) => camelizeKeys(data) as CamelizedOneTimeToken);
}

export async function payInvoices(contactHash: string, paymentRequest: InvoicePaymentRequest): Promise<InvoicePaymentResponseCollection> {
  const url = `/portal/${contactHash}/pay`;
  const decamelizedPaymentRequest = decamelizeKeys(paymentRequest);
  return await axios.post(url, decamelizedPaymentRequest)
    .then((response: AxiosResponse) => camelizeKeys(response.data) as InvoicePaymentResponseCollection)
    .catch((e: AxiosError<AMPErrorResponse>) => {
      handleResponseErrors(e);
      throw e;
    });
}

export async function paymentInfoRequest(contactHash: string, paymentInfo: PaymentInfoRequest): Promise<PaymentInfoResponse> {
  const url = `/portal/${contactHash}/payment-methods`;
  const decamelizedPaymentInfo = decamelizeKeys(paymentInfo);
  return await axios.post(url, decamelizedPaymentInfo)
    .then((response: AxiosResponse) => camelizeKeys(response.data) as PaymentInfoResponse)
    .catch((e: AxiosError<AMPErrorResponse>) => {
      if (!e.response) {
        throw e;
      } else if (e.response.status === 500) {
        throw new Error(e.response.statusText);
      } else {
        const messages = camelizeKeys(e.response.data) as AMPErrorResponse;
        const message = parseErrorMessages(messages);
        throw new Error(message);
      }
    });
}

export async function prepaymentRequest(token: string, gatewayUrl: string, paymentInfo: Record<string, any>): Promise<any> {
  const decamelizedPaymentInfo = decamelizeKeys(paymentInfo);
  try {
    const url = `${determineGatewayUrl(gatewayUrl)}/payments`;
    const response = await axios.post(url, decamelizedPaymentInfo, { headers: { Authorization: `Bearer ${token}`}});
    return camelizeKeys(response.data);
  } catch (e) {
    if (!e.response) {
      throw e;
    } else {
      throw new Error(`${e.response.status === 401 ? 'Your session has expired' : 'There was an error processing the request'}. Please refresh the page and try again.`);
    }
  }
}

export async function featureRequest(token: string, gatewayUrl: string): Promise<Feature[]> {
  const decodedJwt = jwtDecode(token);
  try {
    const url = `${determineGatewayUrl(gatewayUrl)}/v2/merchants/${decodedJwt.sub}/features`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}`}});
    return dataFormatter.deserialize(response.data) as Feature[];
  } catch (e) {
    return [];
  }
}