import { type AMPErrorMessage } from '../types';

// Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#ES6_Custom_Error_Class
export default class UnprocessableEntityError extends Error {
  errorMessages: AMPErrorMessage[];

  constructor(errorMessages: AMPErrorMessage[], ...params: string[]) {
    super(...params);
    this.name = 'UnprocessableEntityError';
    this.errorMessages = errorMessages;
    Error.captureStackTrace(this, UnprocessableEntityError);
  }
}
