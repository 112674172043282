import { type AMPErrorMessage } from '../types';

export function extractGatewayErrorMessage(errorMessage: AMPErrorMessage): string {
  switch (errorMessage.code) {
  case 'not_authorized':
  case 'invalid_use_of_sensitive_data':
    return errorMessage.message;
  case 'no_response':
    return 'Could not reach Payment Gateway';
  default:
    return 'Something went wrong, please try again';
  }
}
