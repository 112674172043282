import { type BankAccountLimitsResponse } from '../types';

export default class BankAccountLimit implements BankAccountLimitsResponse {
  id: string;
  idBase62: string;
  achLimit: number;
  cardLimit: number;
  loanLimit: number;

  static fromJSON(json: BankAccountLimitsResponse): BankAccountLimit {
    const limit = new BankAccountLimit();

    limit.id = json.id;
    limit.idBase62 = json.idBase62;
    limit.achLimit = json.achLimit;
    limit.cardLimit = json.cardLimit;
    limit.loanLimit = json.loanLimit;
    return limit;
  }
}
