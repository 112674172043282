import * as React from 'react';
import { Col, ControlLabel, FormControl, type FormControlProps, FormGroup, Row, Table } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';
import { PaymentFieldTypes } from '../types';
import { formatTotal } from '../utils/formatters';

const BillingInformation = inject('appStore')(observer(({ appStore: {
  paymentAmount,
  currencyDisplayHelper,
  submittingPayment,
  paymentDataStore: model,
  countrySubdivisionStore,
  previewMode
} }: any) => (
  <div>
    <Row>
      <Col lg={8}>

        <div className="form-header">
          <p className="billing-address">Billing Address</p>
        </div>

        <FormGroup
          controlId={PaymentFieldTypes.Address1}
          validationState={model.validationStateFor(PaymentFieldTypes.Address1)}
        >
          <ControlLabel>Address</ControlLabel>
          <FormControl
            className="payment-form__input"
            name={PaymentFieldTypes.Address1}
            value={model[PaymentFieldTypes.Address1]}
            type="text"
            onChange={model.handleChange}
            onBlur={model.touchField}
            disabled={submittingPayment || previewMode}
          />
        </FormGroup>

        <FormGroup
          controlId={PaymentFieldTypes.Address2}
          validationState={model.validationStateFor(PaymentFieldTypes.Address2)}
        >
          <ControlLabel>Address Line 2</ControlLabel>
          <FormControl
            className="payment-form__input"
            name={PaymentFieldTypes.Address2}
            value={model[PaymentFieldTypes.Address2]}
            type="text"
            onChange={model.handleChange}
            onBlur={model.touchField}
            disabled={submittingPayment || previewMode}
          />
        </FormGroup>

        <Row>
          <Col lg={6}>
            <FormGroup
              controlId={PaymentFieldTypes.City}
              validationState={model.validationStateFor(PaymentFieldTypes.City)}
            >
              <ControlLabel>City</ControlLabel>
              <FormControl
                className="payment-form__input"
                name={PaymentFieldTypes.City}
                value={model[PaymentFieldTypes.City]}
                type="text"
                onChange={model.handleChange}
                onBlur={model.touchField}
                disabled={submittingPayment || previewMode}
              />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <StateSelect
              subdivisionTypeName={countrySubdivisionStore.subdivisionTypeName}
              subdivisions={countrySubdivisionStore.subdivisions}
              hasSubdivisions={countrySubdivisionStore.hasSubdivisions}
              onChange={model.handleChange}
              onBlur={model.touchField}
              value={model.state}
              validationState={model.validationStateFor(PaymentFieldTypes.State)}
              disabled={submittingPayment || previewMode}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <FormGroup
              controlId={PaymentFieldTypes.PostalCode}
              validationState={model.validationStateFor(PaymentFieldTypes.PostalCode)}
            >
              <ControlLabel>Postal Code</ControlLabel>
              <FormControl
                className="payment-form__input"
                name={PaymentFieldTypes.PostalCode}
                value={model[PaymentFieldTypes.PostalCode]}
                type="text"
                onChange={model.handleChange}
                onBlur={model.touchField}
                disabled={submittingPayment || previewMode}
              />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <CountrySelect
              disabled={submittingPayment || previewMode}
              value={model[PaymentFieldTypes.Country]}
              validationState={model.validationStateFor(PaymentFieldTypes.Country)}
              onBlur={model.touchField}
              onChange={(e: React.FormEvent<FormControlProps>) => {
                countrySubdivisionStore.setCountry(e.currentTarget.value);
                model.resetState();
                model.handleChange(e);
              }}
            />
          </Col>
        </Row>

      </Col>

      <Col lg={4}>
        <div className="form-header">
          <p className="payment-amount">Payment Amount</p>
        </div>

        <Table className="payment-amount" responsive>
          <thead>
            <tr>
              <th>Payment Form</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Card Payment</td>
              <td className="numeric">{formatTotal(paymentAmount, currencyDisplayHelper)}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </div>
)));

export default BillingInformation;