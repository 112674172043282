import * as React from 'react';
import { Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { PaymentFieldTypes } from '../types';
import MaskedInput from 'react-maskedinput';
import { monthValues, type MonthOptionSelect, yearValues } from '../utils/date-values';

const CreditCardForm = inject('appStore')(observer(({
  appStore: {
    submittingPayment,
    submittingPaymentInfoRequest,
    paymentDataStore: model,
    previewMode,
    isPaymentInfoRequestView
  }

}: any) => {
  return (
    <div>
      <FormGroup
        controlId={PaymentFieldTypes.Name}
        validationState={model.validationStateFor(PaymentFieldTypes.Name)}
      >
        <ControlLabel>Name On Card</ControlLabel>
        <FormControl
          className="payment-form__input"
          name={PaymentFieldTypes.Name}
          value={model[PaymentFieldTypes.Name]}
          type="text"
          onChange={model.handleChange}
          onBlur={model.touchField}
          disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
        />
      </FormGroup>
      <Row>
        <Col lg={isPaymentInfoRequestView ? 12 : 8}>
          <FormGroup
            controlId={PaymentFieldTypes.Number}
            validationState={model.validationStateFor(PaymentFieldTypes.Number)}
          >
            <ControlLabel>Card Number</ControlLabel>
            <MaskedInput
              mask={model.numberFieldMask}
              placeholderChar=" "
              className="form-control payment-form__input"
              name={PaymentFieldTypes.Number}
              value={model[PaymentFieldTypes.Number]}
              type="tel"
              onChange={model.handleNumberChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            />
          </FormGroup>
        </Col>
        {!isPaymentInfoRequestView &&
          <Col lg={4}>
            <FormGroup
              controlId={PaymentFieldTypes.CVV}
              validationState={model.validationStateFor(PaymentFieldTypes.CVV)}
            >
              <ControlLabel>CVV</ControlLabel>
              <FormControl
                className="payment-form__input"
                name={PaymentFieldTypes.CVV}
                value={model[PaymentFieldTypes.CVV]}
                type="tel"
                onChange={model.handleChange}
                onBlur={model.touchField}
                disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
              />
            </FormGroup>
          </Col>
        }
      </Row>

      <Row>
        <Col lg={6}>
          <FormGroup
            controlId={PaymentFieldTypes.ExpMonth}
            validationState={model.validationStateFor(PaymentFieldTypes.ExpMonth)}
          >
            <ControlLabel>Expiration Month</ControlLabel>
            <FormControl
              className="payment-form__select"
              name={PaymentFieldTypes.ExpMonth}
              value={model[PaymentFieldTypes.ExpMonth]}
              componentClass="select"
              onChange={model.handleChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            >
              <option value="">Exp Month</option>
              { monthValues().map((m: MonthOptionSelect) => (
                <option key={m.value} value={m.value}>{m.label}</option>)
              )}
            </FormControl>
          </FormGroup>
        </Col>

        <Col lg={6}>
          <FormGroup
            controlId={PaymentFieldTypes.ExpYear}
            validationState={model.validationStateFor(PaymentFieldTypes.ExpYear)}
          >
            <ControlLabel>Expiration Year</ControlLabel>
            <FormControl
              className="payment-form__select"
              name={PaymentFieldTypes.ExpYear}
              value={model[PaymentFieldTypes.ExpYear]}
              componentClass="select"
              onChange={model.handleChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            >
              <option value="">Exp Year</option>
              {yearValues().map((y: number) => (
                <option key={y} value={y}>{y}</option>)
              )}
            </FormControl>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}));

export default CreditCardForm;
