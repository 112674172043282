import * as React from 'react';
import { Radio } from 'react-bootstrap';
import { css } from 'react-emotion';

const apRadio = css`
  position: relative;
  font-size: 1rem !important;
  padding-left: 30px;

  input[type="radio"] {
    visibility: hidden;

    & ~ .ap-radio {
      background: #FFF;
      border-radius: 50%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 20px;
      width: 20px;
      visibility: visible;
    }

    &:not(:checked) ~ .ap-radio {
      border: 2px solid #666666;
    }

    &:checked ~ .ap-radio {
      border: 6px solid #046FDC;
    }

    &:disabled ~ .ap-radio {
      cursor: not-allowed;
    }

    &:disabled:checked ~ .ap-radio {
      border-color: #666;
    }
  }
`;

export default function ApRadio(props: any) {
  return (
    <Radio className={apRadio} {...props}>
      {props.children}
      <span className="ap-radio" />
    </Radio>
  );
}
