import { type InvoiceResponse, type InvoiceAttachedFile } from '../types';
import { action, observable } from 'mobx';
import { camelize } from 'humps';

export default class Invoice implements InvoiceResponse {
  id: string;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  totalAmount: number;
  amountDue: number;
  reference: string;
  supportedPaymentTypes: string[];
  invoiceAttachments: InvoiceAttachedFile[];
  currency: string;
  bankAccountId: string;
  bankAccountIdBase62: string;
  @observable selected = false;
  @observable testMode = false;

  static fromJSON(json: InvoiceResponse): Invoice {
    const invoice = new Invoice();

    invoice.id = json.id;
    invoice.invoiceNumber = json.invoiceNumber;
    invoice.invoiceDate = json.invoiceDate;
    invoice.dueDate = json.dueDate;
    invoice.totalAmount = json.totalAmount;
    invoice.amountDue = json.amountDue;
    invoice.reference = json.reference;
    invoice.supportedPaymentTypes = json.supportedPaymentTypes.map(pt => camelize(pt));
    invoice.invoiceAttachments = json.invoiceAttachments;
    invoice.currency = json.currency;
    invoice.bankAccountId = json.bankAccountId;
    invoice.bankAccountIdBase62 = json.bankAccountIdBase62;
    invoice.testMode = json.testMode;
    return invoice;
  }

  @action.bound toggleSelected(): void {
    this.selected = !this.selected;
  }
}
