import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { parse, format } from 'date-fns';
import checkIcon from '../images/thank-you-check.svg';
import lockIcon from '../images/lock-icon.svg';

const getPaymentMethodLogo = (paymentType: string) => require(`../images/icon-payment-method-${paymentType}.svg`);

const paymentTypes: Record<string, string> = {
  credit_card: 'Card',
  bank_account: 'eCheck'
};

const brandMapper: Record<string, string> = {
  affinipay: 'AffiniPay',
  lawpay: 'LawPay',
  cpacharge: 'CPA Charge',
  designpay: 'DesignPay',
  clientpay: 'ClientPay',
  medpay: 'MedPay'
};

const PaymentRequestInfoSuccess = inject('appStore')(observer(({ appStore: { contact, paymentInfoResponse, currentBrand } }: any) => {
  const {
    paymentType,
    accountHolderType,
    bankAccountType,
    name,
    givenName,
    surname,
    accountNumber,
    address: { line1: address1, line2: address2, city, postalCode, stateOrProvince },
    created: { timestamp },
    cardType
  } = paymentInfoResponse;
  const paymentMethodLogo = cardType ? getPaymentMethodLogo(cardType.toLowerCase()) : getPaymentMethodLogo('bank');
  const parsedDate = parse(timestamp);
  const isBank = paymentType === 'bank_account';
  const isCreditCard = paymentType === 'credit_card';

  return (
    <div className="payment-request-success">
      <Row>
        <Col lg={12} className="success-header">
          <img src={checkIcon} alt="Thank you icon" className="check-icon"/>
          <h2>Thank You!</h2>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col lg={12}>
          <h4 className="success-subtext">
            We received your information.
          </h4>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="payment-request-info">
            <img src={lockIcon} alt="Lock icon" className="lock-icon"/>
            <Row>
              <Col lg={12}>
                <h4 className="uppercase">Contact Information</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={5} lg={3}>First Name</Col>
              <Col xs={7} lg={9}>{contact.firstName}</Col>
            </Row>
            <Row>
              <Col xs={5} lg={3}>Last Name</Col>
              <Col xs={7} lg={9}>{contact.lastName}</Col>
            </Row>
            <Row className="last-contact-field">
              <Col xs={5} lg={3}>Email Address</Col>
              <Col xs={7} lg={9}>{contact.preferredEmail}</Col>
            </Row>
            <Row>
              <Col lg={12}>
                <h4 className="uppercase">Payment Method</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={5} lg={3}>Type</Col>
              <Col xs={7} lg={9}>
                {paymentTypes[paymentType]}
                {isBank && <span className="capitalize"> - {accountHolderType} {bankAccountType} Account</span>}
              </Col>
            </Row>
            {isCreditCard &&
              <Row>
                <Col xs={5} lg={3}>Name on Card</Col>
                <Col xs={7} lg={9}>{name}</Col>
              </Row>
            }
            {isBank && accountHolderType === 'individual' &&
              <div>
                <Row>
                  <Col xs={5} lg={3}>First Name</Col>
                  <Col xs={7} lg={9}>{givenName}</Col>
                </Row>
                <Row>
                  <Col xs={5} lg={3}>Last Name</Col>
                  <Col xs={7} lg={9}>{surname}</Col>
                </Row>
              </div>
            }
            {isBank && accountHolderType === 'business' &&
              <Row>
                <Col xs={5} lg={3}>Account Holder</Col>
                <Col xs={7} lg={9}>{name}</Col>
              </Row>
            }
            <Row>
              <Col xs={5} lg={3}>{isCreditCard ? <span>Card</span> : <span>Account</span>} Number</Col>
              <Col xs={7} lg={9}>
                Encrypted in
                <span className="capitalize"> {brandMapper[currentBrand] || 'AffiniPay'}</span>&apos;s
                Electronic Card Vault -
                {paymentMethodLogo && <img src={paymentMethodLogo} alt="Payment Method Logo" className="payment-method-logo" />}
                {accountNumber}
              </Col>
            </Row>
            {isCreditCard &&
              <Row>
                <Col xs={5} lg={3}>Expiration Date</Col>
                <Col xs={7} lg={9}>On File</Col>
              </Row>
            }
            {isBank &&
              <Row>
                <Col xs={5} lg={3}>Routing Number</Col>
                <Col xs={7} lg={9}>On File</Col>
              </Row>
            }
            <Row>
              <Col xs={5} lg={3}>Billing Address</Col>
              <Col xs={7} lg={9}>
                {address1 && <div>{address1} <br/></div>}
                {address2 && <div>{address2} <br /></div>}
                {city && <span>{city}, </span>}
                {stateOrProvince && <span>{stateOrProvince} </span>}
                {postalCode}
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg={12}>
              <p>The electronic form was completed on {format(parsedDate, 'MMMM Do, YYYY')} at {format(parsedDate, 'h:mm a')}.</p>
              <p>
                You agreed: a) that when you clicked the “Save My Payment Information” button
                and submitted your payment information to us, your payment information was
                securely stored in a Payment Information Vault; and b) You specifically authorize
                the use of that information as your payment method for the services provided under
                our agreement to provide professional services; and c) In the event your payment
                information becomes invalid with charges pending, you will provide a new valid
                payment method upon request, to be used to pay any outstanding balances owed.
              </p>
              <p>
                No information stored shall ever be shared or used for any purpose other
                than the designated payment method.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}));

export default PaymentRequestInfoSuccess;
