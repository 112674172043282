import * as React from 'react';
import { Row, Col, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { UITransitionActions } from '../types';
import { css } from 'react-emotion';

import CreditCardForm from './CreditCardForm';
import AchForm from './AchForm';

import ccIcon from '../images/icn_cc.svg';
import achIcon from '../images/icn_ach.svg';

const tab = css`
  a {
    text-align: left !important;
    img {
      margin-right: 0.5em;
      height: 30px;
    }
  }
`;

const PaymentInformation = inject('appStore')(observer(({ appStore: { selectedInvoicesAvailablePaymentTypes, submittingPayment, paymentDataStore: model, previewMode, transitionToNewStep, isPaymentAmountUnderAchLimit, togglePaymentDisclaimer } }: any) => {
  return (
    <div>
      <Row>
        <Col lg={8}>

          <div className="form-header">
            <p className="payment-information">Payment Information</p>
          </div>

          <TabContainer
            id="tabs-payment-type"
            activeKey={model.paymentType}
            onSelect={(key) => { model.setValue('paymentType', key); }}
          >
            <div>
              <Nav bsStyle="tabs" justified onSelect={(selectedKey) => togglePaymentDisclaimer(selectedKey)}>
                {selectedInvoicesAvailablePaymentTypes.indexOf('creditCard') !== -1 && <NavItem className={tab} eventKey="creditCard"><img src={ccIcon} />Pay with card</NavItem>}
                {(selectedInvoicesAvailablePaymentTypes.indexOf('bankAccount') !== -1 && isPaymentAmountUnderAchLimit) &&
                  <NavItem className={tab} eventKey="bankAccount">
                    <img src={achIcon} />Pay with eCheck
                  </NavItem>
                }
              </Nav>
              <TabContent>
                <TabPane eventKey="creditCard">
                  <CreditCardForm />
                </TabPane>
                <TabPane eventKey="bankAccount">
                  <AchForm />
                </TabPane>
              </TabContent>
            </div>
          </TabContainer>

        </Col>
      </Row>

      <Row className="payment-actions">
        <Col lg={8}>
          <button
            className="button pull-right"
            type="submit"
            disabled={submittingPayment || previewMode}
            style={{marginRight: '0px'}}
          >
            Pay Now
          </button>

          <button
            className="button button--secondary pull-right"
            disabled={submittingPayment}
            type="button"
            onClick={() => {
              model.resetPaymentInformation();
              transitionToNewStep(UITransitionActions.ShowInvoices);
            }}
          >
            Back
          </button>
        </Col>
      </Row>
    </div>
  );
}));

export default PaymentInformation;
