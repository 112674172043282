import { consoleLogger } from './logger';
import { getCurrentBrand } from './brand-parser';
import { loadScript } from './genericHelpers';

declare const window: any;

const qaGoogleAnalyticsIDs: Record<string, string> = {
  affinipay: 'G-HZSE76FTRV',
  lawpay: 'G-HZSE76FTRV',
  cpacharge: 'G-HZSE76FTRV',
  clientpay: 'G-HZSE76FTRV',
  designpay: 'G-HZSE76FTRV',
  medpay: 'G-HZSE76FTRV'
};

export const initializeGoogleAnalytics = (env: NodeJS.ProcessEnv) => {
  const currentBrand = getCurrentBrand(window.location.hostname);
  const analyticsKeyMapper = env.REACT_APP_GOOGLE_ANALYTICS_KEYS ? JSON.parse(env.REACT_APP_GOOGLE_ANALYTICS_KEYS) : qaGoogleAnalyticsIDs;
  const googleAnalyticsKey = analyticsKeyMapper[currentBrand] || analyticsKeyMapper['affinipay'];

  loadScript(`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`);
  handleGoogleAnalytics(googleAnalyticsKey);
};

const handleGoogleAnalytics = (googleAnalyticsKey: string) => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function (...args: any[]) {
    window.dataLayer.push(args);
  };
  window.gtag('js', new Date());
  window.gtag('config', googleAnalyticsKey);
};

export const safeSendGA = (category = '', action = '', label = '') => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  } else {
    consoleLogger(`Failed to send GA v4 event: ${category}, ${action}, ${label}`, 'warning');
  }
};