import type * as React from 'react';
import { action, computed, observable } from 'mobx';
import Validator from 'validatorjs';
import { camelize } from 'humps';
import { type AppStore } from './appStore';
import { type CamelizedOneTimeToken, GatewayPaymentTypes, SupportedPaymentTypes, PaymentFieldTypes, AccountHolderType } from '../types';

interface TouchedPaymentFields {
  paymentType: boolean
  number: boolean
  expMonth: boolean
  expYear: boolean
  cvv: boolean
  accountNumber: boolean
  accountType: boolean
  routingNumber: boolean
  accountHolderType: boolean
  name: boolean
  givenName: boolean
  surname: boolean
  accountName: boolean
  address1: boolean
  address2: boolean
  city: boolean
  state: boolean
  postalCode: boolean
  country: boolean
}

export class PaymentDataStore {
  appStore: AppStore;
  @observable paymentType: 'creditCard' | 'bankAccount';
  @observable hasAmountSelected = false;
  @observable number = '';
  @observable accountNumber = '';
  @observable accountType = 'CHECKING';
  @observable routingNumber = '';
  @observable accountHolderType: string = AccountHolderType.Individual;
  @observable expMonth = '';
  @observable expYear = '';
  @observable cvv = '';
  @observable name = '';
  @observable givenName = '';
  @observable surname = '';
  @observable accountName = '';
  @observable address1 = '';
  @observable address2 = '';
  @observable city = '';
  @observable state = '';
  @observable postalCode = '';
  @observable country = 'US';

  @observable errors: Record<string, string> | null = {};

  @observable creditCardValidationRules: Record<string, string> = {
    hasAmountSelected: 'accepted',
    number: 'required',
    expMonth: 'required',
    expYear: 'required'
  };

  @observable achValidationRules: Record<string, string> = {
    hasAmountSelected: 'accepted',
    accountNumber: 'required',
    routingNumber: 'required',
    accountHolderType: 'required',
    accountType: 'required',
    name: 'required'
  };

  @observable touchedFields: TouchedPaymentFields = {
    paymentType: false,
    number: false,
    expMonth: false,
    expYear: false,
    cvv: false,
    accountNumber: false,
    accountType: false,
    routingNumber: false,
    accountHolderType: false,
    name: false,
    givenName: false,
    surname: false,
    accountName: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    postalCode: false,
    country: false
  };
  currency: string;

  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  @action.bound touchField(event: React.FormEvent<HTMLInputElement>): void {
    this.touchedFields[event.currentTarget.name as keyof TouchedPaymentFields] = true;
    this.validateModel();
  }

  @action touchAllFields(value: boolean): void {
    Object.keys(this.touchedFields).forEach(f => {
      this.touchedFields[f as keyof TouchedPaymentFields] = value;
    });
  }

  @action.bound handleChange(event: React.FormEvent<HTMLInputElement>): void {
    // @ts-expect-error type string not compatible with inherent any
    this[event.currentTarget.name] = event.currentTarget.value;
    this.validateModel();
  }

  @action.bound handleNumberChange(event: React.FormEvent<HTMLInputElement>): void {
    // @ts-expect-error type string not compatible with inherent any
    this[event.currentTarget.name] = event.currentTarget.value.replace(/\s/g, '');
    this.validateModel();
  }

  @action.bound setValue(key: string, value: any): void {
    // @ts-expect-error type string not compatible with inherent any
    this[key] = value;
    this.validateModel();
  }

  @action toggleAmountSelected(amountSelected: boolean): void {
    this.hasAmountSelected = amountSelected;
    this.validateModel();
  }

  @computed get isPaymentInfoRequestView(): boolean {
    return this.appStore.isPaymentInfoRequestView;
  }

  // Validations
  @computed get valid(): boolean {
    return !this.errors;
  }

  @computed get validationRules(): object {
    if (this.paymentType === SupportedPaymentTypes.BankAccount) {
      if (this.accountHolderType === 'individual') {
        const { name , ...rules } = {
          ...this.achValidationRules,
          givenName: 'required',
          surname: 'required'
        } as Record<string, string>;
        return rules;
      } else {
        const { name, ...rules } = {
          ...this.achValidationRules,
          accountName: 'required'
        } as Record<string, string>;
        return rules;
      }
    } else {
      return this.creditCardValidationRules;
    }
  }

  @computed get paymentInfoRequestValidationRules(): object {
    const paymentInfoRequestCardRules = {
      name: 'required',
      postalCode: 'required|regex:/^[a-zA-Z0-9- ]{3,10}$/',
      number: 'required|regex:/^[0-9]{13,18}$/',
      expMonth: 'required',
      expYear: 'required'
    };
    const paymentInfoRequestAchRules = {
      accountName: this.accountHolderType === 'business' ? 'required' : 'present',
      givenName: this.accountHolderType === 'individual' ? 'required' : 'present',
      surname: this.accountHolderType === 'individual' ? 'required' : 'present',
      postalCode: 'required|regex:/^[a-zA-Z0-9- ]{3,10}$/',
      accountHolderType: 'required',
      accountType: 'required',
      accountNumber: 'required|regex:/^[0-9]{4,17}$/',
      routingNumber: 'required|digits:9'
    };
    return this.paymentType === SupportedPaymentTypes.CreditCard ? paymentInfoRequestCardRules : paymentInfoRequestAchRules;
  }

  @action validateModel(): void {
    const rules = this.isPaymentInfoRequestView ? this.paymentInfoRequestValidationRules : this.validationRules;
    const validation = new Validator(this, rules);
    if (validation.passes()) {
      this.errors = null;
    } else {
      this.errors = validation.errors.all();
    }
  }

  @action.bound submit(e: React.SyntheticEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.validateModel();
  }

  @action resetState(): void {
    this.state = '';
  }

  @action.bound resetPaymentInformation(): void {
    this.touchAllFields(false);
    ['number', 'name', 'cvv', 'expMonth', 'expYear', 'accountNumber', 'routingNumber', 'givenName', 'surname'].forEach(field => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[field] = '';
    });
  }

  @action setCreditCardValidationRules(rules: string[]): void {
    this.setValidationRules(rules, 'creditCardValidationRules');
  }

  @action setAchValidationRules(rules: string[]): void {
    this.setValidationRules(rules, 'achValidationRules');
  }

  @action setValidationRules(rules: string[], storage: string): void {
    if (!rules) { return; }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this[storage] = rules.reduce(
      (validationRules: Record<string, string>, rule: string): object => {
        const camelizedRule: string = camelize(rule);

        if (this.validPaymentFields.includes(camelizedRule)) {
          validationRules[camelizedRule] = 'required';
        }

        return validationRules;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      { ...this[storage]}
    );
  }

  validationStateFor(attribute: string): string | undefined {
    if (this.touchedFields[attribute as keyof TouchedPaymentFields] && this.errors && this.errors[attribute]) {
      return 'error';
    }
    return undefined;
  }

  @computed get oneTimeTokenData(): CamelizedOneTimeToken {
    return this.oneTimeTokenDataFields.reduce(
      (data: CamelizedOneTimeToken, attribute: string) => {
        if (attribute === PaymentFieldTypes.Number) {
          data[attribute] = this[attribute].replace(/\s/g, '');
        } else if (attribute === 'accountName') {
          data.name = this[attribute];
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[attribute as keyof CamelizedOneTimeToken] = this[attribute];
        }
        return data;
      },
      { type: this.gatewayPaymentType }
    );
  }

  @computed get gatewayPaymentType(): GatewayPaymentTypes {
    if (this.paymentType === 'creditCard') {
      return GatewayPaymentTypes.CreditCard;
    } else {
      return GatewayPaymentTypes.BankAccount;
    }
  }

  @computed get oneTimeTokenDataFields(): string[] {
    if (this.paymentType === SupportedPaymentTypes.BankAccount) {
      const nameFields = this.accountHolderType === 'individual' ? ['givenName', 'surname'] : ['accountName'];
      return ['accountNumber', 'accountType', 'routingNumber', 'accountHolderType', 'address1', 'address2', 'city', 'state', 'postalCode', 'country'].concat(nameFields);
    } else {
      return ['number', 'expMonth', 'expYear', 'cvv', 'name', 'address1', 'address2', 'city', 'state', 'postalCode', 'country'];
    }
  }

  @computed get validPaymentFields(): string[] {
    return [
      PaymentFieldTypes.Number,
      PaymentFieldTypes.ExpMonth,
      PaymentFieldTypes.ExpYear,
      PaymentFieldTypes.Country,
      PaymentFieldTypes.CVV,
      PaymentFieldTypes.City,
      PaymentFieldTypes.Address1,
      PaymentFieldTypes.Address2,
      PaymentFieldTypes.Name,
      PaymentFieldTypes.State,
      PaymentFieldTypes.PostalCode,
      PaymentFieldTypes.AccountNumber,
      PaymentFieldTypes.RoutingNumber,
      PaymentFieldTypes.AccountHolderType,
      PaymentFieldTypes.GivenName,
      PaymentFieldTypes.Surname
    ];
  }

  @computed get numberFieldMask (): string {
    if (/^3[47]/.test(this.number)) {
      return '1111 111111 11111';
    }
    return '1111 1111 1111 1111 11';
  }
}

export default PaymentDataStore;
