import { type MerchantResponse } from '../types';

export default class Merchant implements MerchantResponse {
  name: string;
  address: string;
  additionalAddress: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  website: string;
  supportEmail: string;
  emailSuccessTo: string;
  emailFailureTo: string;
  referenceLabel: string;
  publicKey: string;
  phone: string;
  supportPhone: string;
  logoUrl: string;
  disclaimerHtml: string;

  static fromJSON(json: MerchantResponse): Merchant {
    const merchant = new Merchant();
    merchant.name = json.name;
    merchant.address = json.address;
    merchant.additionalAddress = json.additionalAddress;
    merchant.city = json.city;
    merchant.state = json.state;
    merchant.postalCode = json.postalCode;
    merchant.country = json.country;
    merchant.website = json.website;
    merchant.supportEmail = json.supportEmail;
    merchant.emailSuccessTo = json.emailSuccessTo;
    merchant.emailFailureTo = json.emailFailureTo;
    merchant.referenceLabel = json.referenceLabel;
    merchant.publicKey = json.publicKey;
    merchant.phone = json.phone;
    merchant.supportPhone = json.supportPhone;
    merchant.logoUrl = json.logoUrl;
    merchant.disclaimerHtml = json.disclaimerHtml;

    return merchant;
  }
}
