import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { AchFieldTypes } from '../types';
import { Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import Radio from './ApRadio';

const AchForm = inject('appStore')(observer(({ appStore: { submittingPayment, submittingPaymentInfoRequest, paymentDataStore: model, previewMode } }: any) => {
  return (
    <div>
      <FormGroup>
        <ul className="nav nav-justified">
          <li>
            <Radio
              name="accountHolderType"
              value="individual"
              checked={model.accountHolderType === 'individual'}
              onChange={model.handleChange}
              onBlur={model.touchField}
              inline
            >
              Personal Account
            </Radio>
          </li>
          <li>
            <Radio
              name="accountHolderType"
              value="business"
              checked={model.accountHolderType === 'business'}
              onChange={model.handleChange}
              onBlur={model.touchField}
              inline
            >
              Business Account
            </Radio>
          </li>
        </ul>
      </FormGroup>
      <Row>
        <Col lg={6}>
          <FormGroup
            controlId={AchFieldTypes.AccountType}
            validationState={model.validationStateFor(AchFieldTypes.AccountType)}
          >

            <ControlLabel>Account Type</ControlLabel>
            <FormControl
              className="payment-form__select"
              name={AchFieldTypes.AccountType}
              componentClass="select"
              value={model[AchFieldTypes.AccountType]}
              onChange={model.handleChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            >
              <option key="0" value="">Account type</option>
              <option key="checking" value="CHECKING">Checking</option>
              <option key="savings" value="SAVINGS">Savings</option>
            </FormControl>
          </FormGroup>
        </Col>
      </Row>
      { model.accountHolderType === 'business' && (
        <FormGroup
          controlId={AchFieldTypes.AccountName}
          validationState={model.validationStateFor(AchFieldTypes.AccountName)}
        >
          <ControlLabel>Name of Business</ControlLabel>
          <FormControl
            className="payment-form__input"
            name={AchFieldTypes.AccountName}
            type="text"
            value={model.accountName}
            onChange={model.handleChange}
            onBlur={model.touchField}
            disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
          />
        </FormGroup>)}
      { model.accountHolderType === 'individual' && (
        <Row>
          <Col lg={6}>
            <FormGroup
              controlId={AchFieldTypes.GivenName}
              validationState={model.validationStateFor(AchFieldTypes.GivenName)}
            >
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                className="payment-form__input"
                name={AchFieldTypes.GivenName}
                type="text"
                value={model.givenName}
                onChange={model.handleChange}
                onBlur={model.touchField}
                disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup
              controlId={AchFieldTypes.Surname}
              validationState={model.validationStateFor(AchFieldTypes.Surname)}
            >
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                className="payment-form__input"
                name={AchFieldTypes.Surname}
                type="text"
                value={model.surname}
                onChange={model.handleChange}
                onBlur={model.touchField}
                disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={6}>
          <FormGroup
            controlId={AchFieldTypes.RoutingNumber}
            validationState={model.validationStateFor(AchFieldTypes.RoutingNumber)}
          >

            <ControlLabel>ABA Routing Number</ControlLabel>
            <FormControl
              className="payment-form__input"
              name={AchFieldTypes.RoutingNumber}
              type="text"
              onChange={model.handleChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup
            controlId={AchFieldTypes.AccountNumber}
            validationState={model.validationStateFor(AchFieldTypes.AccountNumber)}
          >
            <ControlLabel>Account Number</ControlLabel>
            <FormControl
              className="payment-form__input"
              name={AchFieldTypes.AccountNumber}
              type="text"
              onChange={model.handleChange}
              onBlur={model.touchField}
              disabled={submittingPayment || submittingPaymentInfoRequest || previewMode}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}));

export default AchForm;
