import * as React from 'react';

const NotFound = () => (
  <div className="not-found">
    <h1 className="sr-only">Sorry! 404 page not found</h1>
    <p className="sr-only">You may have mistyped the address.</p>
  </div>
);

export default NotFound;
