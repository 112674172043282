import React, { Component, useEffect } from 'react';
import { Col, Grid } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { jwtDecode } from 'jwt-decode';
import MerchantHeader from './MerchantHeader';
import InvoicesTable from './InvoicesTable';
import PaymentForm from './PaymentForm';
import PaymentRequestForm from './PaymentRequestForm';
import Footer from './Footer';
import ExpiredPortal from './ExpiredPortal';
import FetchError from './FetchError';
import PaymentSuccess from './PaymentSuccess';
import PreviewBanner from './PreviewBanner';
import { UISteps } from '../types';
import { APSpinner, APToast } from 'affinipay-ui-library';
import UpfInvoicesTable from './UpfInvoicesTable';
import UpfPaymentForm from './UpfPaymentForm';
import PaymentTerms from './PaymentTerms';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { consoleLogger } from '../utils/logger';

const PortalComponent = inject('appStore')(observer(({
  appStore: {
    currentBrand,
    merchant,
    currentStep,
    upfEnabled,
    isPaymentInfoRequestView,
    previewMode,
    notice,
    dismissNotice,
    portalJWT
  }
}: any) => {
  const { upfEnabledLd } = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if(merchant && currentBrand && portalJWT) {
      try {
        const decodedJWT = jwtDecode(portalJWT);
        ldClient?.identify({
          kind: 'merchant',
          key: decodedJWT.sub,
          name: merchant.name,
          country: merchant.country,
          brand: currentBrand
        });
      } catch (e) {
        consoleLogger(`Failed to configure LDClient: ${e.message}`, 'error');
      }
    }
  },[merchant, currentBrand, portalJWT]);

  const isUpfEnabled = upfEnabled || upfEnabledLd;
  let RenderPaymentForm;
  const showPaymentTerms = !isPaymentInfoRequestView && !isUpfEnabled;

  if(currentStep === UISteps.Payment) {
    RenderPaymentForm = isUpfEnabled ? UpfPaymentForm : PaymentForm;
  } else if (isPaymentInfoRequestView) {
    RenderPaymentForm = PaymentRequestForm;
  } else {
    RenderPaymentForm = isUpfEnabled ? UpfInvoicesTable : InvoicesTable;
  }

  if (currentStep === UISteps.Loading) {
    return <APSpinner />;
  }

  if (currentStep === UISteps.InitialDataFetchError) {
    return <FetchError />;
  }

  if (currentStep === UISteps.ExpiredLink) {
    return <ExpiredPortal />;
  }

  if (currentStep === UISteps.PaymentSuccess) {
    return <PaymentSuccess />;
  }

  return (
    <div>
      {previewMode ? (<PreviewBanner />) : null}
      {!isPaymentInfoRequestView &&
        <MerchantHeader />
      }
      { notice.show
        ? (
          <>
            <APToast
              isOpen={true}
              type={notice.type}
              onClose={dismissNotice}
              messages={[notice.message]}
            />
          </>

        )
        : null }

      <Grid fluid>
        <Col lg={isPaymentInfoRequestView || isUpfEnabled ? 12 : 8}>
          <RenderPaymentForm />
          {showPaymentTerms && <PaymentTerms />}
          <Footer />
        </Col>
      </Grid>
    </div>
  );
}));


@inject('appStore')
@observer
class Portal extends Component<any> {
  async UNSAFE_componentWillMount() {
    const { appStore } = this.props;
    await appStore.init(this.props);
  }

  render() {
    return <PortalComponent />;
  }
}

export default Portal;
