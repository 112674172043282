import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
// styles
import 'bootstrap/dist/css/bootstrap.css';
import 'typeface-source-sans-pro';
import 'typeface-lato';
import './styles/index.css';

import App from './components/App';
import appStore from './stores/appStore';
import paymentDataStore from './stores/paymentDataStore';
import { unregister } from './registerServiceWorker';
import { initializeUPF } from './utils/genericHelpers';
import { initializeGoogleAnalytics } from './utils/googleAnalyticsHelpers';
import { consoleLogger } from './utils/logger';

// Mobx setup
const stores = {
  appStore,
  paymentDataStore
};

if (process.env.NODE_ENV === 'development') {
  (window as any).__APP_STATE__ = stores;
}

initializeUPF(process.env);
initializeGoogleAnalytics(process.env);

(async () => {
  if(!process.env.REACT_APP_LAUNCH_DARKLY_CID) {
    consoleLogger('Missing LaunchDarkly Clientside ID!', 'warning');
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CID || '663d0c8016eefc0ffd2391e9'
  });

  ReactDOM.render(
    <LDProvider>
      <Provider {...stores}>
        <App />
      </Provider>
    </LDProvider>,
    document.getElementById('root') as HTMLElement
  );
})();

// https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#opting-out-of-caching
unregister();
