import * as React from 'react';
import { ControlLabel, FormControl, type FormControlProps, FormGroup } from 'react-bootstrap';
import { type Subdivision } from 'country-subdivisions';
import { PaymentFieldTypes } from '../types';

interface StateSelectProps {
  disabled?: boolean
  subdivisionTypeName: string
  subdivisions?: Subdivision[]
  hasSubdivisions: boolean
  value: string
  validationState?: 'success' | 'warning' | 'error'
  onChange: (e: React.FormEvent<FormControl & FormControlProps>) => void
  onBlur: (e: React.FormEvent<FormControl & FormControlProps>) => void
}

interface StateOptionProps {
  subdivision: Subdivision
}

const StateOption: React.FC<StateOptionProps> = ({ subdivision }: StateOptionProps) => (
  <option value={subdivision.code()}>
    {subdivision.name()}
  </option>
);

const StateSelect: React.FC<StateSelectProps> = ({ disabled, subdivisionTypeName, subdivisions, hasSubdivisions, onChange, value, validationState, onBlur }: StateSelectProps) => {
  return (
    <FormGroup
      controlId={PaymentFieldTypes.State}
      validationState={validationState}
    >
      <ControlLabel>State</ControlLabel>
      {
        hasSubdivisions
          ? (
            <FormControl
              className="payment-form__select"
              name={PaymentFieldTypes.State}
              value={value}
              componentClass="select"
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            >
              <option value="">Select {subdivisionTypeName || 'State'}</option>
              {subdivisions?.map((sd: Subdivision) => (
                <StateOption key={sd.code()} subdivision={sd} />
              ))}
            </FormControl>
          )
          : (
            <FormControl
              className="payment-form__input"
              name={PaymentFieldTypes.State}
              type="text"
              placeholder={subdivisionTypeName || 'State'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          )
      }
    </FormGroup>
  );
};

export default StateSelect;
