import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import NotFound from './NotFound';
import Portal from './Portal';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={(props: any) => <Portal {...props} />} />
      <Route path="/invoice-payments/:id" component={(props: any) => <Portal {...props} />} />
      <Route component={() => <NotFound />} />
    </Switch>
  </Router>
);

export default App;
