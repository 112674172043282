import * as React from 'react';
import { FormControl, FormGroup, ControlLabel, type FormControlProps } from 'react-bootstrap';
import Country from 'country-subdivisions';
import { PaymentFieldTypes } from '../types';

interface CountrySelectProps {
  value: string
  validationState?: 'success' | 'warning' | 'error'
  disabled?: boolean

  onChange: (e: React.FormEvent<FormControl & FormControlProps>) => void
  onBlur: (e: React.FormEvent<FormControl & FormControlProps>) => void
}

interface CountryOptionProps {
  country: Country
}

const CountryOption: React.FC<CountryOptionProps> = ({ country }: CountryOptionProps) => (
  <option value={country.code()}>
    {country.name()}
  </option>
);

const CountrySelect: React.FC<CountrySelectProps> = ({ disabled, value, onChange, onBlur, validationState }: CountrySelectProps) => {
  const countries: Country[] = Array.from(Country.getCountries());

  const filteredCountries = countries.filter((country) => country.name() !== 'Puerto Rico');

  return (
    <FormGroup
      controlId={PaymentFieldTypes.Country}
      validationState={validationState}
    >
      <ControlLabel>Country</ControlLabel>
      <FormControl
        className="payment-form__select"
        name={PaymentFieldTypes.Country}
        componentClass="select"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option value="">Select Country</option>
        {filteredCountries.map((c: Country) => <CountryOption key={c.code()} country={c} />)}
      </FormControl>
    </FormGroup>
  );
};

export default CountrySelect;
