import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { getBrandedPrivacyLink } from '../utils/branded-utils';

// Use inline style for now
const paymentTermsStyles = {
  marginTop: '20px'
};

const setTerms = (terms: string) => {
  return { __html: terms };
};

const disclaimerMessage = (merchant: { name: string; disclaimerHtml: string; }, eCheckSelected: boolean, currentBrand: string) => {
  if (eCheckSelected) {
    return (
      <span>
        By clicking “Submit Payment”, I am authorizing {merchant.name} to initiate a one-time ACH/electronic debit in the amount indicated from the bank account I have designated above.
        The debit is to occur approximately 3-5 business days from submission and I understand that the authorization will remain in full force and effect until the transaction
        is cancelled by me by contacting {merchant.name}, or the ACH/electronic debit is processed from the designated account.
        Any such request for cancellation shall afford {merchant.name} a reasonable opportunity to act on such request.
        I certify that (1) I am authorized to debit the bank account above and (2) that the ACH/electronic payment I am authorizing complies with all applicable laws.
        By clicking “Submit Payment”, you agree to pay the above amount and provide your information to help process your payment and detect fraud.
        Please see our {' '}
        <a href={getBrandedPrivacyLink(currentBrand)} rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>{' '} for more information on our data practices.
      </span>
    );
  }

  return <div dangerouslySetInnerHTML={setTerms(merchant.disclaimerHtml)} />;
};

const PaymentTerms = inject('appStore')(observer(({
  appStore: {
    merchant,
    eCheckSelected,
    currentBrand
  }
}: any) => {
  return (
    <div style={paymentTermsStyles}>
      {disclaimerMessage(merchant, eCheckSelected, currentBrand)}
    </div>
  );
}));

export default PaymentTerms;