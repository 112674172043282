import * as React from 'react';
import { inject, observer } from 'mobx-react';
import alertIcon from '../images/error-alert-icon.svg';

const FetchError = inject('appStore')(observer(({ appStore: { failureMessage } }: any) => {
  return (
    <div className='error-page'>
      <img className='error-page-icon' alt='Error Alert Icon' src={alertIcon} />
      <h1 className='error-page-header'>{failureMessage}</h1>
    </div>
  );
}));

export default FetchError;
