import * as React from 'react';
import { inject } from 'mobx-react';
import { formatPhone } from '../utils/formatters';
import { Row, Col } from 'react-bootstrap';

const MerchantSidebar = inject('appStore')(({ appStore: { merchant } }: any) => {
  return (
    <section className="sidebar">
      <div className="sidebar__merchant-logo">
        {merchant.logoUrl
          ? (
            <img
              src={merchant.logoUrl}
              alt="Merchant Logo"
            />
          )
          : (
            <div className="sidebar__img-placeholder" />
          )}
      </div>

      <div className="sidebar__merchant-details hidden-xs">
        <Row>
          <Col lg={12}>
            <address>
              <p className="merchant-name">{merchant.name}</p>
              <p>{merchant.address}</p>
              {merchant.additionalAddress && <p>{merchant.additionalAddress}</p>}
              <p>{merchant.city}, {merchant.state} {merchant.postalCode}</p>
            </address>

            <address>
              <p className="phone-number">
                <a className="hidden-xs" href={`tel:${merchant.phone as string}`}>{formatPhone(merchant.phone)}</a>
                <a className="hidden-lg hidden-md hidden-sm" href={`tel:${merchant.phone as string}`}>Phone</a>
              </p>
              <p className="merchant-misc-info">
                <a className="hidden-xs" href={`mailto:${merchant.supportEmail as string}`}>{merchant.supportEmail}</a>
                <a className="hidden-lg hidden-md hidden-sm" href={`mailto:${merchant.supportEmail as string}`}>Email</a>
              </p>
              <p className="merchant-misc-info">
                <a className="hidden-xs" href={merchant.website}>{merchant.website}</a>
                <a className="hidden-lg hidden-md hidden-sm" href={merchant.website}>Web Site</a>
              </p>
            </address>
          </Col>
        </Row>
      </div>

    </section>
  );
});

export default MerchantSidebar;
