import * as React from 'react';

const PreviewBanner = () => {
  return (
    <div className="preview-banner">
      <p>
        Preview of Outstanding Bills
      </p>
    </div>
  );
};

export default PreviewBanner;
