import Rollbar from 'rollbar';

const filteredFields = [
  'AccountNumber',
  'accountNumber',
  'address'
];

const rollbarOptions = {
  accessToken: '60a7cc5442014aa0a7b4f2d6fec29526',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  payload: {
    environment: process.env.REACT_APP_ENV ?? 'development'
  },
  scrubFields: filteredFields
};

const rollbar = new Rollbar(rollbarOptions);

export const log = {
  debug: (msg: string, params: Rollbar.LogArgument[] = []) => rollbar.debug([msg, ...params]),
  critical: (msg: string, params: Rollbar.LogArgument[] = []) => rollbar.critical([msg, ...params]),
  error: (msg: string, params: Rollbar.LogArgument[] = []) => rollbar.error([msg, ...params]),
  info: (msg: string, params: Rollbar.LogArgument[] = []) => rollbar.info([msg, ...params]),
  warn: (msg: string, params: Rollbar.LogArgument[] = []) => rollbar.warn([msg, ...params])
};

export function consoleLogger(message?: any, ...args: any[]): void {
  // log to the console based on the ENV variable
  if (process.env.REACT_APP_LOG_TO_CONSOLE) {
    console.log(message, ...args);
  }
}
