import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import Checkbox from './Checkbox';

const AuthorizationCheckbox = inject('appStore')(observer(({
  appStore: {
    submittingPaymentInfoRequest,
    previewMode,
    paymentInfoRequestAuthorized,
    handleAuthorizationChange
  }

}: any) => {
  return (
    <Row>
      <Col lg={12} className="card-vault-authorization-container">
        <Checkbox
          checkboxID="card-vault-authorization"
          checked={paymentInfoRequestAuthorized}
          onChange={handleAuthorizationChange}
          disabled={submittingPaymentInfoRequest || previewMode}
        />
        <span>
          You are agreeing: a) that when you click the “Save My Payment Information” button below and submit your payment information to us, your payment information shall be securely stored in a Payment Information Vault; and b) You specifically authorize the use of that information as your payment method for the services provided under our agreement; and c) In the event your payment information becomes invalid with charges pending, you will provide a new valid payment method upon request, to be used to pay any outstanding balances owed.
          <br/> <br/> <p>No information stored shall ever be shared or used for any purpose other than the designated payment method.</p>
        </span>
      </Col>
    </Row>
  );
}));

export default AuthorizationCheckbox;
