import * as React from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import type Invoice from '../stores/invoice';
import { formatDate, formatTotal } from '../utils/formatters';
import { UITransitionActions } from '../types';
import Checkbox from './Checkbox';
import invoiceAttachments from './InvoiceAttachment';

const InvoicesTable = inject('appStore')(observer(({ appStore }: any) => {
  const attachmentTableHeading = () => {
    return (appStore.hasAttachments ? (<th>Attachment(s)</th>) : null);
  };

  const attachmentTableColumn = (invoice: Invoice) => {
    return (appStore.hasAttachments ? (<td>{invoiceAttachments(appStore.contactHash, invoice)}</td>) : null);
  };

  return (
    <div className="invoices-step">
      <p className="legend">Select the outstanding bill(s) to pay below:</p>
      <Table responsive striped condensed>
        <thead>
          <tr>
            <th>
              <Checkbox
                checkboxID="select-all"
                checked={appStore.allSelected}
                onChange={() => appStore.toggleAllInvoices(false)}
                disabled={appStore.previewMode}
              /> Date
            </th>
            <th>{appStore.merchant.referenceLabel}</th>
            <th>Payment Form</th>
            {attachmentTableHeading()}
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {appStore.invoices.map((invoice: Invoice) => {
            const {
              amountDue,
              currency,
              id,
              invoiceDate,
              reference,
              selected,
              supportedPaymentTypes,
              totalAmount
            } = invoice;
            return (
              <tr key={id}>
                <td className="numeric">
                  {amountDue > 0 ?
                    <span>
                      <Checkbox
                        checkboxID={`checkbox-${id}`}
                        checked={selected}
                        onChange={() => appStore.toggleSelectedInvoice(invoice, false)}
                        disabled={appStore.previewMode}
                      /> {formatDate(invoiceDate)}
                    </span>
                    : <span className="invoice-date">{formatDate(invoiceDate)}</span>
                  }
                </td>
                <td>{reference}</td>
                <td>
                  {supportedPaymentTypes.indexOf('creditCard') !== -1 && <span>Card</span>}
                  {(supportedPaymentTypes.indexOf('bankAccount') !== -1 && totalAmount <= appStore.getInvoiceAchLimit(invoice)) &&
                    <span>{supportedPaymentTypes.length > 1 && ', '}eCheck</span>
                  }
                </td>
                {attachmentTableColumn(invoice)}
                <td className="numeric">{formatTotal(amountDue, currency)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row>
        <Col lg={4} lgOffset={8}>
          <p className="payment-amount"> Payment Amount <span>{formatTotal(appStore.paymentAmount, appStore.currencyDisplayHelper)} </span> </p>
          {!appStore.hasMultipleCurrencies && (
            <p className="remaining-due">
              Remaining Due &nbsp;&nbsp;&nbsp;<span>{formatTotal(appStore.outstandingBalance, appStore.currencyDisplayHelper)}</span>
            </p>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={4} lgOffset={8}>
          <button
            type="button"
            disabled={appStore.previewMode || appStore.hasError || (appStore.paymentAmount + appStore.outstandingBalance) === 0}
            className="pull-right button button__enter-payment"
            onClick={() => {
              if (appStore.hasSelectedAnInvoice) {
                appStore.transitionToNewStep(UITransitionActions.ShowPaymentForm);
              } else {
                appStore.setNotice('error', 'Please select the bills you\'d like to pay at this time.');
              }
            }}
          >
            Enter Payment
          </button>
        </Col>
      </Row>
    </div>
  );
}));

export default InvoicesTable;