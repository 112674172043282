import * as PhoneFormatter from 'phone-formatter';
import { parse, format } from 'date-fns';
import { camelize } from 'humps';
import Dinero from 'dinero.js';
import { getUserLocale } from './genericHelpers';

export const roundNumber = (amount: number, decimals = 0): number => {
  amount = Number(amount);
  if (amount === 0) return 0;
  if (!amount || Object.prototype.toString.call(amount) !== '[object Number]') {
    throw `Invalid value sent to roundNumber: ${amount}`;
  }
  const isNegative = amount < 0;
  let num = Number(amount);
  if (isNegative)
    num = num * -1;
  const result = Number(Math.round(Number(num + 'e' + decimals)) + 'e-' + decimals);
  return isNegative ? result * -1 : result;
};

export const getRoundedMonetaryNumber = (amount: number): number => roundNumber(amount, 2);

export const getCentsFromDollars = (dollars: number): number => {
  if (dollars === 0) return 0;
  if (!dollars) {
    throw `Invalid value sent to getCentsFromDollars: ${dollars}`;
  }
  const dollarsSTR: string = Number(dollars) + '';
  const decimals: number = dollarsSTR.substring(dollarsSTR.indexOf('.') + 1).length;
  if (dollarsSTR.indexOf('.') === -1) {
    return Number(dollarsSTR + '00');
  } else if (decimals === 1) {
    return Number((getRoundedMonetaryNumber(dollars) + '0').replace('.', ''));
  } else if (decimals >= 2) {
    return Number((getRoundedMonetaryNumber(dollars) + '').replace('.', ''));
  } else {
    return 0;
  }
};

export const getDollarsFromCents = (cents: number): number => {
  if (cents === 0) return 0;
  if (!cents) {
    throw `Invalid value sent to getDollarsFromCents: ${cents}`;
  }
  return getRoundedMonetaryNumber(cents / 100);
};

export const formatTotal = (amountInCents: number, selectedCurrency = 'USD'): string => {
  if (amountInCents !== 0 &&
    (!amountInCents || !parseInt(amountInCents.toString(), 10))
  ) { return ''; }

  return Dinero({
    amount: amountInCents,
    currency: selectedCurrency.toUpperCase() as Dinero.Currency || 'USD'
  }).setLocale(getUserLocale()).toFormat('$0,0.00');
};

export const formatTotalDollars = (dollars: number, currency = 'usd'): string => {
  if (!dollars) dollars = 0;
  dollars = getRoundedMonetaryNumber(dollars);

  return Dinero({
    amount: getCentsFromDollars(dollars),
    currency: currency.toUpperCase() as Dinero.Currency || 'USD'
  }).setLocale(getUserLocale()).toFormat('$0,0.00');
};

export const formatPhone = (phone: string): string => {
  if (!phone) { return ''; }

  return PhoneFormatter.format(phone, '(NNN) NNN-NNNN');
};

export const formatDate = (date: string): string => {
  if (!date) { return ''; }

  return format(parse(date), 'MM/DD/YYYY');
};

export const capitalize = (s: string): string => {
  return s ? s.charAt(0).toUpperCase() + s.slice(1) : s;
};

export const camelizePaymentFieldEntries = (requiredPaymentFields: Record<string, any>): object => {
  return Object.keys(requiredPaymentFields).reduce(
    (paymentFields: Record<string, string>, key: string) => {
      paymentFields[key] = requiredPaymentFields[key].map((f: string) => camelize(f));
      return paymentFields;
    },
    {}
  );
};
