import { UISteps, UITransitionActions } from '../types';

// eslint-disable-next-line
export default class ApplicationFSM {
  static machine: Record<string, Record<string, number>> = {
    [UISteps.Initial]: {
      [UITransitionActions.FetchInitialData]: UISteps.Loading,
      [UITransitionActions.InitialDataFetchFailure]: UISteps.InitialDataFetchError
    },
    [UISteps.Loading]: {
      [UITransitionActions.InitialDataFetchSuccessExpired]: UISteps.ExpiredLink,
      [UITransitionActions.InitialDataFetchSuccess]: UISteps.Invoices,
      [UITransitionActions.InitialDataFetchFailure]: UISteps.InitialDataFetchError,
      [UITransitionActions.InitialDataFetchPaymentInfoRequest]: UISteps.PaymentInfoRequest
    },
    [UISteps.InitialDataFetchError]: {},
    [UISteps.ExpiredLink]: {},
    [UISteps.Invoices]: {
      [UITransitionActions.ShowPaymentForm]: UISteps.Payment
    },
    [UISteps.Payment]: {
      [UITransitionActions.ShowInvoices]: UISteps.Invoices,
      [UITransitionActions.MadeSuccessfulPayment]: UISteps.PaymentSuccess
    },
    [UISteps.PaymentSuccess]: {
      [UITransitionActions.BackToInvoices]: UISteps.Invoices
    },
    [UISteps.PaymentInfoRequest]: {
      [UITransitionActions.MadeSuccessfulPaymentInfoRequest]: UISteps.PaymentInfoRequestSuccess
    },
    [UISteps.PaymentInfoRequestSuccess]: {}
  };

  static transitionToState(currentState: UISteps, action: UITransitionActions) {
    const actions = this.machine[currentState];
    if (!actions) { return currentState; }

    const nextState = actions[action];
    if (!nextState) { return currentState; }

    return nextState;
  }
}
