import React, { useRef, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatTotal, formatTotalDollars } from '../utils/formatters';
import { type UPFCheckoutProps } from '../upf-checkout';
import type Invoice from '../stores/invoice';
import { UITransitionActions } from '../types';
import { decamelizeKeys } from 'humps';
import { safeSendGA } from '../utils/googleAnalyticsHelpers';
import { generatePrepaymentBody } from '../utils/genericHelpers';
import { prepaymentRequest } from '../utils/requester';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getSurchargeLabel = (surchargeState: SurchargeState, currency = 'USD'): string => {
  const { surchargeFee, surchargeLabel, surchargePercent } = surchargeState;
  const isPercentSurcharge = !surchargeFee || parseInt(surchargeFee) === 0;
  const amount = isPercentSurcharge ? `${surchargePercent}%` : formatTotalDollars(parseFloat(surchargeFee), currency);
  return `${surchargeLabel} (${amount})`;
};

const sendGAPaymentSuccessEvent = (method: string, isSurcharged: boolean) => {
  if (method === 'loan') {
    safeSendGA('UPF', 'Pay Later: Application Approved', 'Invoicing-Portal');
    return;
  }
  const isSurchargedLabel = isSurcharged ? 'Surcharge applied' : 'No surcharge';
  safeSendGA('UPF', `Payment Successful: ${method === 'card' ? 'Card' : 'eCheck'} ${isSurchargedLabel}`, 'Invoicing-Portal');
};

const getDisabledPaymentMethods = (supportedPaymentTypes: string[], isPaymentAmountUnderAchLimit: boolean, isPaymentAmountUnderLoanLimit: boolean) => {
  const disabledPaymentTypes: string[] = [];
  if (supportedPaymentTypes.includes('bankAccount') && !isPaymentAmountUnderAchLimit) disabledPaymentTypes.push('ach');
  if (supportedPaymentTypes.includes('loan') && !isPaymentAmountUnderLoanLimit) disabledPaymentTypes.push('loan');
  return disabledPaymentTypes.join(',');
};

const getUpfParams = (appStore: any, submitDisabled: boolean, errors: string, invoiceReferences: string[]) => {
  const paymentMethods = [...new Set(...appStore.selectedInvoices.map((i: Invoice) => i.supportedPaymentTypes))] as string[];
  const disabledPaymentMethods = getDisabledPaymentMethods(paymentMethods, appStore.isPaymentAmountUnderAchLimit, appStore.isPaymentAmountUnderLoanLimit);
  const upfParams: UPFCheckoutProps = {
    amount: appStore.paymentAmount,
    token: appStore.portalJWT,
    'bank-account-id': appStore.selectedInvoices[0].bankAccountIdBase62,
    'public-key': appStore.merchant.publicKey,
    'disabled-payment-methods': disabledPaymentMethods,
    'source-id': appStore.sourceId,
    'test-mode': appStore.testMode,
    'submit-disabled': submitDisabled,
    errors: errors,
    reference: invoiceReferences.length === 1 ? invoiceReferences[0] : ''
  };
  return upfParams;
};

type SurchargeState = {
  surchargeTotal: number,
  totalAmount: number,
  surchargeLabel: string,
  surchargePercent: string,
  surchargeFee: ''
}

const UpfPaymentForm = inject('appStore')(observer(({ appStore }: any) => {
  const { upfInvoicingSurcharge } = useFlags();
  const [surchargeState, setSurchargeState] = useState<SurchargeState>({
    surchargeTotal: 0,
    totalAmount: appStore.paymentAmount,
    surchargeLabel: '',
    surchargePercent: '',
    surchargeFee: ''
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errors, setErrors] = useState('');
  const upfRef = useRef<any>();
  const invoiceReferences: string[] = appStore.selectedInvoices.map((i: Invoice) => i.reference).filter((reference?: string) => reference);
  const upfParams = getUpfParams(appStore, submitDisabled, errors, invoiceReferences);

  useEffect(() => {
    upfRef.current['allocations'] = decamelizeKeys(appStore.invoiceAllocations);
    upfRef.current['custom-data'] = {
      context: 'portal',
      ...(invoiceReferences.length > 1 && { references: invoiceReferences })
    };
    upfRef.current['default-fields'] = { email: appStore.contact.emailAddresses?.[0]?.address || '' };
  }, []);

  useEffect(() => {
    safeSendGA('UPF', 'UPF Opened', 'Invoicing-Portal');
  }, []);

  useEffect(() => {
    const chargeSuccessListener = (e: any) => {
      const chargePayload = { ...e.detail };
      sendGAPaymentSuccessEvent(chargePayload.method.type, !!chargePayload?.surcharge_amount);
      appStore.removeSelected();
      appStore.transitionToNewStep(UITransitionActions.MadeSuccessfulPayment);
    };
    document.addEventListener('UPFChargeSuccess', chargeSuccessListener);
    return () => {
      document.removeEventListener('UPFChargeSuccess', chargeSuccessListener);
    };
  }, []);

  useEffect(() => {
    const paymentMethodChangeListener = (e: any) => {
      const { surchargeTotal, surchargeLabel, totalAmount, surchargePercent, surchargeFee } = e.detail;
      setSurchargeState({surchargeTotal, surchargeLabel, totalAmount, surchargeFee, surchargePercent});
    };
    document.addEventListener('UPFPaymentMethodChange', paymentMethodChangeListener);
    return () => {
      document.removeEventListener('UPFPaymentMethodChange', paymentMethodChangeListener);
    };
  }, []);

  useEffect(() => {
    const chargeFailureListener = async () => {
      setSubmitDisabled(true);
      try {
        // create new prepayment for next transaction attempt
        const newPrepaymentBody = generatePrepaymentBody(appStore);
        const resp = await prepaymentRequest(appStore.portalJWT, appStore.gatewayUrl, newPrepaymentBody);
        appStore.setPrepaymentId(resp[0].id);
      } catch (e) {
        setErrors(e.message);
      } finally {
        setSubmitDisabled(false);
      }
    };
    document.addEventListener('UPFChargeFailure', chargeFailureListener);
    return () => {
      document.removeEventListener('UPFChargeFailure', chargeFailureListener);
    };
  }, [appStore.prepaymentId]);

  return (
    <div className='upf-payment-step'>
      <div className='upf-container'>
        <h4 className='upf-header'>Payment Method</h4>
        <upf-checkout
          {...upfParams}
          surcharge-disabled={!upfInvoicingSurcharge && !appStore.upfSurchargeEnabled}
          ref={upfRef}
        />
      </div>
      <div className='summary-container'>
        <div className="form-header">
          <p>Summary</p>
        </div>
        <hr />
        <div className='summary-row'>
          <span>Payment</span>
          <span className="numeric">{formatTotal(appStore.paymentAmount, appStore.currencyDisplayHelper)}</span>
        </div>
        {surchargeState.surchargeTotal > 0 &&
          <div className='summary-row'>
            <span>{getSurchargeLabel(surchargeState, appStore.currencyDisplayHelper)}</span>
            <span className="numeric">{formatTotal(surchargeState.surchargeTotal, appStore.currencyDisplayHelper)}</span>
          </div>
        }
        <hr />
        <div className='summary-row'>
          <b>Total Payment</b>
          <span className="numeric">{formatTotal(surchargeState.totalAmount, appStore.currencyDisplayHelper)}</span>
        </div>
      </div>
    </div>
  );
}));

export default UpfPaymentForm;
