import * as React from 'react';
import { Row, Col, Nav, NavItem, Tabs, TabContent, TabPane } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import CreditCardForm from './CreditCardForm';
import AchForm from './AchForm';
import { css } from 'react-emotion';
import ccIcon from '../images/icn_cc.svg';
import achIcon from '../images/icn_ach.svg';

const tab = css`
  a {
    text-align: left !important;
    img {
      margin-right: 0.5em;
      height: 30px;
    }
  }
`;

const PaymentRequestInformation = inject('appStore')(observer(({
  appStore: {
    paymentDataStore: model
  }

}: any) => {
  return (
    <div>
      <div className="form-header">
        <h3>Enter Your Payment Method</h3>
      </div>
      <Row>
        <Col lg={12}>
          <Tabs
            id="tabs-payment-type"
            activeKey={model.paymentType}
            onSelect={(key) => { model.setValue('paymentType', key); }}
          >
            <div>
              <Nav bsStyle="tabs" justified>
                <NavItem className={tab} eventKey="creditCard">
                  <img src={ccIcon} />
                  Pay with card
                </NavItem>
                <NavItem className={tab} eventKey="bankAccount">
                  <img src={achIcon} />
                  Pay with eCheck
                </NavItem>
              </Nav>
              <TabContent>
                <TabPane eventKey="creditCard">
                  <CreditCardForm />
                </TabPane>
                <TabPane eventKey="bankAccount">
                  <AchForm />
                </TabPane>
              </TabContent>
            </div>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}));

export default PaymentRequestInformation;
