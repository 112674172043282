import React, { useState } from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import type Invoice from '../stores/invoice';
import { formatDate, formatTotal } from '../utils/formatters';
import { UITransitionActions } from '../types';
import Checkbox from './Checkbox';
import invoiceAttachments from './InvoiceAttachment';
import { prepaymentRequest } from '../utils/requester';
import { generatePrepaymentBody } from '../utils/genericHelpers';

const UpfInvoicesTable = inject('appStore')(observer(({ appStore }: any) => {
  const [nextDisabled, setNextDisabled] = useState(false);

  const onPaymentClick = () => {
    if (!appStore.hasSelectedAnInvoice) {
      appStore.setNotice('error', 'Please select the bills you\'d like to pay at this time.');
      return;
    }
    createPrePayment();
  };

  const createPrePayment = async () => {
    setNextDisabled(true);
    appStore.setTestMode(appStore.selectedInvoices[0].testMode);

    try {
      const prepaymentBody = generatePrepaymentBody(appStore);
      const resp = await prepaymentRequest(appStore.portalJWT, appStore.gatewayUrl, prepaymentBody);
      appStore.setPrepaymentId(resp[0].id);
      appStore.transitionToNewStep(UITransitionActions.ShowPaymentForm);
    } catch (e) {
      appStore.setNotice('error', e.message);
      setNextDisabled(false);
    }
  };

  return (
    <div className="invoices-step">
      <p className="legend">Your outstanding bill:</p>
      <Table responsive striped condensed>
        <thead>
          <tr>
            <th>
              <Checkbox
                checkboxID="select-all"
                checked={appStore.allSelected}
                onChange={() => appStore.toggleAllInvoices(true)}
                disabled={appStore.previewMode}
              />
            </th>
            <th>
              Date
            </th>
            <th>{appStore.merchant.referenceLabel}</th>
            <th>Accepted Payment Method(s)</th>
            {appStore.hasAttachments && <th>Attachment(s)</th>}
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {appStore.invoices.map((invoice: Invoice) => {
            const {
              amountDue,
              currency,
              id,
              invoiceDate,
              reference,
              selected,
              supportedPaymentTypes,
              totalAmount
            } = invoice;

            return (
              <tr key={id}>
                <td className="numeric">
                  {amountDue > 0 &&
                    <span>
                      <Checkbox
                        checkboxID={`checkbox-${id}`}
                        checked={selected}
                        onChange={() => appStore.toggleSelectedInvoice(invoice, true)}
                        disabled={appStore.previewMode}
                      />
                    </span>
                  }
                </td>
                <td className='numeric'>
                  <span className="invoice-date">{formatDate(invoiceDate)}</span>
                </td>
                <td>{reference}</td>
                <td>
                  {supportedPaymentTypes.includes('creditCard') && <span>Card</span>}
                  {(supportedPaymentTypes.includes('bankAccount') && totalAmount <= appStore.getInvoiceAchLimit(invoice)) &&
                    <span>{supportedPaymentTypes.length > 1 && ', '}eCheck</span>
                  }
                  {(supportedPaymentTypes.includes('loan') && totalAmount <= appStore.getInvoiceLoanLimit(invoice)) &&
                    <span>{supportedPaymentTypes.length > 1 && ', '}Pay Later</span>
                  }
                </td>
                {appStore.hasAttachments && <td>{invoiceAttachments(appStore.contactHash, invoice)}</td>}
                <td className="numeric">{formatTotal(amountDue, currency)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col lg={4} lgOffset={8} className='amount-due'>
          <p className="payment-amount"> Payment Amount <span>{formatTotal(appStore.paymentAmount, appStore.currencyDisplayHelper)} </span> </p>
          {!appStore.hasMultipleCurrencies && (
            <p className="remaining-due">
              Remaining Due &nbsp;&nbsp;&nbsp;<span>{formatTotal(appStore.outstandingBalance, appStore.currencyDisplayHelper)}</span>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4} lgOffset={8}>
          <button
            type="button"
            disabled={nextDisabled || appStore.previewMode || appStore.hasError || (appStore.paymentAmount as number + (appStore.outstandingBalance as number)) === 0}
            className="pull-right button button__enter-payment"
            onClick={nextDisabled ? undefined : onPaymentClick}
          >
            {nextDisabled ? 'Loading...' : 'Enter Payment'}
          </button>
        </Col>
      </Row>
    </div>
  );
}));

export default UpfInvoicesTable;
