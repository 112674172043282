import * as qs from 'query-string';

interface MatchProps {
  isExact: boolean
  path: string
  url: string

  params: any
}

interface LocationProps {
  hash: string
  pathname: string
  search: string
}

interface RoutingProps {
  match: MatchProps
  location: LocationProps
}

export const extractContactHash = (props: RoutingProps): string => {
  if (props.location.search) {
    return qs.parse(props.location.search).c;
  } else {
    return props.match.params.id;
  }
};

export const hasContactHash = (props: RoutingProps): boolean => {
  if (props.location.search) {
    const parsedQueryString = qs.parse(props.location.search);
    return !!parsedQueryString.c;
  }

  return !!props.match.params.id;
};

export const hasPreviewMode = (props: RoutingProps): boolean => {
  if (props.location.search) {
    const parsedQueryString = qs.parse(props.location.search);
    return !!parsedQueryString.preview;
  }

  return false;
};

export const hasPaymentInfoRequest = (props: RoutingProps): boolean => {
  if (props.location.search) {
    const parsedQueryString = qs.parse(props.location.search);
    return 'payment-info' in parsedQueryString;
  }

  return false;
};
