import * as React from 'react';
import MerchantHeader from './MerchantHeader';
import { Col, Grid } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { APToast } from 'affinipay-ui-library';

const ExpiredPortal = inject('appStore')(observer(({ appStore }: any) => {
  return (
    <div className="expired">
      <MerchantHeader />
      { appStore.notice.show
        ? (
          <APToast
            isOpen={true}
            type={appStore.notice.type}
            messages={[appStore.notice.message]}
            onClose={appStore.dismissNotice}
          />
        )
        : null }

      <Grid fluid>
        <Col lg={6} lgOffset={3} className="text-center">
          <div className="link-expired-container">
            <p className="legend">Link Expired</p>
            <p> Your payment link has expired. </p>
            <p>Please use the button below to request a fresh link.</p>
            <button
              className="btn btn-primary"
              onClick={appStore.requestNewPortalLink}
              disabled={appStore.requestingNewPortalLink}
            >
              Request Fresh Link
            </button>
          </div>
        </Col>
      </Grid>

    </div>
  );
}));

export default ExpiredPortal;
